import { FormTextBlock, FormField, Dropdown, Checkbox, Input, FormSubmit } from "@fonk-gitlab/bpfd-portal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { updateUserCorrespondenceSettings } from "../../react-query/mutations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import Loading from "../organisms/Loading";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { Preferences } from "../../lib/types";
import { HREF } from "../../routes/routes";
import { UNAUTHORIZED_ERROR_CODE, refreshAuthToken } from "../../utils/token";
import {
  TCorrespondenceDetailsSchema,
  useCorrespondenceDetailsSchema,
} from "../../hooks/schemas/generalSchema";
import { CorrespondenceType } from "../../lib/enum";
import { getCommunicationPreferences } from "../../react-query/queries";
import { APILanguage } from "../../utils/languageLoader";
import { useMemo } from "react";
import { useIntlMessage } from "../../hooks/useIntlMessage";
import { getPreferencesOrDefault } from "../../utils/defaultPreferences";

interface Props {
  preferences: Preferences;
  userId: string;
}

const UserCorrespondenceDetailsFormChild: React.FC<Props> = ({ preferences, userId }) => {
  const intlMessage = useIntlMessage();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const dropdownLanguage = useMemo(
    () => [
      {
        id: "1",
        key: "1",
        value: APILanguage.EN,
        label: intlMessage("utils.lang.en"),
      },
      {
        id: "2",
        key: "2",
        value: APILanguage.NL,
        label: intlMessage("utils.lang.nl"),
      },
    ],
    [intlMessage]
  );

  const dropdownCommunication = useMemo(
    () => [
      {
        id: "1",
        key: "1",
        value: CorrespondenceType.DIGITAL,
        label: intlMessage("my-data-correspondence-settings.way-of-communication.D"),
      },
      {
        id: "2",
        key: "2",
        value: CorrespondenceType.POST,
        label: intlMessage("my-data-correspondence-settings.way-of-communication.P"),
      },
    ],
    [intlMessage]
  );

  const {
    mutateAsync,
    isPending,
    error: postError,
  } = useMutation({
    mutationFn: updateUserCorrespondenceSettings,
    onSuccess: () => {
      if (getValues("language").value !== preferences?.language) {
        queryClient.invalidateQueries({
          queryKey: ["user"],
        });
      }

      queryClient.invalidateQueries({
        queryKey: ["communication", userId],
      });
      navigate(HREF.MY_DATA_TAB_2);
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutateAsync(data);
          }
        });
      }
      setError("language", { message: postError?.message });
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    setError,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<TCorrespondenceDetailsSchema>({
    mode: "onChange",
    resolver: zodResolver(useCorrespondenceDetailsSchema()),
    resetOptions: { keepDefaultValues: false },
    defaultValues: {
      language: dropdownLanguage.find((option) => option.value === preferences.language),
      wayOfCommunication: dropdownCommunication.find(
        (option) => option.value === preferences.wayOfCommunication
      ),
      email: preferences?.email ?? "",
      isReceiveNews: preferences?.isReceiveNews ?? false,
    },
  });

  const onSubmit = async (data: FieldValues) => {
    await mutateAsync({
      language: data.language?.value,
      wayOfCommunication: data.wayOfCommunication?.value,
      email: data.email,
      isReceiveNews: data.isReceiveNews,
    } as Preferences);
    reset();
  };

  return (
    <div className="bg-gradient-mint-blue-light">
      <div className="container flex-grow py-20">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5 md:col-start-4 space-y-4">
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <FormTextBlock heading={<FormattedMessage id="my-data.contact-details" />} />
              <FormField
                className="col-span-12"
                id="language"
                labelText={<FormattedMessage id="my-data-correspondence-settings.language" />}>
                <Dropdown name="language" items={dropdownLanguage} control={control} />
              </FormField>
              <FormField
                className="col-span-12"
                id="wayOfCommunication"
                labelText={<FormattedMessage id="my-data-correspondence-settings.legal" />}>
                <Dropdown items={dropdownCommunication} name="wayOfCommunication" control={control} />
              </FormField>
              <FormField className="col-span-12" id="email" labelText={<FormattedMessage id="utils.email" />}>
                <Input
                  id="email"
                  name="email"
                  control={control}
                  placeholder={intlMessage("utils.email")}
                  disabled={isSubmitting || isPending}
                  error={!!errors.email}
                />
              </FormField>
              <FormField className="col-span-12" id="isReceiveNews">
                <Controller
                  name="isReceiveNews"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id="isReceiveNews"
                      checked={value}
                      onChange={onChange}
                      label={<FormattedMessage id="my-data-correspondence-settings.newsletter" />}
                      errorSpacing={false}
                    />
                  )}
                />
              </FormField>
              <FormField id="language">
                <FormSubmit
                  buttons={[
                    {
                      ariaLabel: "button",
                      text: <FormattedMessage id="utils.save-changes" />,
                      type: "submit",
                      disabled: !isDirty || isSubmitting || !isValid,
                    },
                    {
                      ariaLabel: "link",
                      onClick: () => {
                        navigate(-1);
                      },
                      text: <FormattedMessage id="utils.cancel" />,
                    },
                  ]}
                  errorMessage={postError?.message}
                />
              </FormField>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserCorrespondenceDetailsForm = () => {
  const data = useAuthentication();
  const { data: preferences, isLoading: comIsLoading } = useQuery({
    queryKey: ["communication", data?.user?.id],
    queryFn: getCommunicationPreferences,
    select: (data) => getPreferencesOrDefault(data.preferences[0] as Preferences),
    enabled: !!data?.user?.id,
  });

  if (data?.isLoading || preferences === undefined || comIsLoading) {
    return <Loading />;
  }

  return <UserCorrespondenceDetailsFormChild preferences={preferences} userId={data?.user?.id ?? ""} />;
};

export default UserCorrespondenceDetailsForm;
