import React from "react";
import { subMonths, isAfter, parseISO, format } from "date-fns";
import {
  DatePicker,
  DropdownItem,
  FormField,
  FormImportantMessage,
  FormSubmit,
  LabeledText,
  Popup,
  Text,
} from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import useGenderOption from "../../../hooks/useGenderOption";
import { useIntlMessage } from "../../../hooks/useIntlMessage";
import { useForm } from "react-hook-form";
import { EndCohabitation, useEndCohabitationSchema } from "../../../hooks/schemas/partnerSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthentication } from "../../../context/AuthenticatedContext";
import PartnerDetailPreview from "../../organisms/partner/PartnerDetailPreview";
import { refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../../../utils/token";
import { useMutation } from "@tanstack/react-query";
import { updateEndCohabitation } from "../../../react-query/mutations";
import { DATE_FORMAT } from "../../../lib/constants";
import { HREF } from "../../../routes/routes";
import { useNavigate } from "react-router-dom";

export interface EndPartnerFormProps {
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  gender: string;
  bsn: string;
  startDate: string;
}

const END_COHABITATION_DEFAULT: EndCohabitation = {
  endDate: undefined,
};

const EndPartnerForm: React.FC<EndPartnerFormProps> = ({
  bsn,
  dob,
  firstName,
  middleName,
  gender,
  lastName,
  startDate,
}) => {
  const intlMessage = useIntlMessage();
  const navigate = useNavigate();
  const { user, refetchToggles, refetchUser } = useAuthentication() || {};
  const [preSubmit, setPreSubmit] = React.useState<boolean>(false);
  const [popup, setPopup] = React.useState<boolean>(false);
  const [bsnError, setBsnError] = React.useState<string>("");
  const {
    formState: { errors, isDirty, isSubmitting },
    setValue,
    handleSubmit,
    watch,
  } = useForm<EndCohabitation>({
    resolver: zodResolver(useEndCohabitationSchema()),
    defaultValues: END_COHABITATION_DEFAULT,
  });
  const {
    mutateAsync: mutateCohabitation,
    isPending,
    mutate,
  } = useMutation({
    mutationFn: updateEndCohabitation,
    onSuccess: () => {
      setBsnError("");
      setPopup(true);
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutate(data);
          }
        });
      }
      if ((postError.cause as { message: string })?.message === "MESSAGE_INVALID_VALUE_BSN") {
        setBsnError(intlMessage("validation.bsn.invalid"));
      } else {
        setBsnError(intlMessage("error.request"));
      }
    },
  });
  const { endDate } = watch();
  const gender_list: DropdownItem[] = useGenderOption();

  const handleDate = (name: keyof EndCohabitation, value: unknown) => {
    setValue(name, value as Date, {
      shouldDirty: true,
    });
  };

  const handleCancelPreview = () => {
    setPreSubmit(false);
  };

  const handleSubmitValue = (_: EndCohabitation) => {
    setPreSubmit(true);
  };

  const handleUpdateCohabitation = () => {
    mutateCohabitation({
      endDate: format(endDate as Date, DATE_FORMAT.YYYYMMDD),
    });
  };

  const handleClosePopup = () => {
    setPopup(false);
    refetchToggles?.();
    refetchUser?.();
    navigate(HREF.ACTIONS);
  };

  const isWarning = React.useMemo<boolean>(() => {
    const startDateValue: Date | null = startDate.length > 0 ? parseISO(startDate) : null;

    if (endDate && startDateValue !== null) {
      const sixMonthsBeforeEndDate = subMonths(endDate, 5);

      if (
        isAfter(startDateValue, sixMonthsBeforeEndDate) ||
        (user?.brpPostalCode !== user?.partnerBrpPostalCode &&
          user?.brpHouseNumber !== user?.partnerBrpHouseNumber &&
          user?.brpHouseNumberSuffix !== user?.partnerBrpHouseNumberSuffix)
      )
        return true;
    }

    return false;
  }, [endDate, user, startDate]);
  const errors_messages = React.useMemo<Record<keyof EndCohabitation, string>>(() => {
    return {
      endDate: errors?.endDate?.message ?? "",
    };
  }, [errors?.endDate]);

  return (
    <>
      {bsnError.length > 0 && (
        <FormImportantMessage
          className="mb-6"
          body={<FormattedMessage id="error.request" />}
          heading={<FormattedMessage id="error.validation-fail.title" />}
        />
      )}
      {preSubmit ? (
        <PartnerDetailPreview
          firstName={firstName ?? ""}
          lastName={lastName ?? ""}
          middleName={middleName ?? ""}
          bsn={bsn}
          gender={gender}
          dob={dob}
          startDate={startDate}
          endDate={endDate ? format(endDate, DATE_FORMAT.YYYYMMDD) : ""}
          loading={isPending}
          error={bsnError}
          onCancel={handleCancelPreview}
          onSubmit={() => handleUpdateCohabitation()}
        />
      ) : (
        // preview wrapper
        <form onSubmit={handleSubmit(handleSubmitValue)}>
          <div className="flex flex-col gap-6 shadow-lg mb-6">
            <LabeledText label={<FormattedMessage id="utils.lastName" />} input={lastName} />
            <LabeledText label={<FormattedMessage id="utils.firstName" />} input={firstName} />
            <LabeledText
              label={<FormattedMessage id="utils.birthdate" />}
              input={format(parseISO(dob), DATE_FORMAT.DDMMYYYY)}
            />
            <LabeledText
              label={<FormattedMessage id="utils.gender" />}
              input={gender_list.find((item) => item.value === gender)?.label ?? ""}
            />
            <LabeledText label={<FormattedMessage id="partner.bsn.detail.title" />} input={bsn} />
            <LabeledText
              label={<FormattedMessage id="partner.start.date" />}
              input={format(parseISO(startDate), DATE_FORMAT.DDMMYYYY)}
            />
          </div>
          <FormField id="endDate" labelText={<FormattedMessage id="partner.end.cohabitation.date" />}>
            <div className="mb-4 text-red">
              {isWarning && (
                <Text size="xs">
                  <FormattedMessage id="utils.end-date.warning" />
                </Text>
              )}
            </div>
            <DatePicker
              name="endDate"
              ariaLabelIconButton="Icon date picker"
              placeholder={intlMessage("utils.select.date.placeholder")}
              value={endDate as string | undefined}
              feedbackMessage={errors_messages.endDate}
              onChange={(value) => handleDate("endDate", value)}
              error={!!errors.endDate}
            />
          </FormField>
          <FormField id="submit">
            <FormSubmit
              buttons={[
                {
                  size: "md",
                  ariaLabel: "link",
                  onClick: () => {
                    navigate(-1);
                  },
                  text: <FormattedMessage id="utils.cancel" />,
                },
                {
                  size: "md",
                  ariaLabel: "button",
                  text: <FormattedMessage id="utils.multistep.forward" />,
                  type: "submit",
                  disabled: !isDirty || isSubmitting,
                },
              ]}
            />
          </FormField>
        </form>
      )}
      <Popup
        ariaLabelCloseIcon="Sluiten"
        body={<FormattedMessage id="partner-detail.cohabitation.confirm" />}
        popupVariant="information"
        open={popup}
        onClose={handleClosePopup}
      />
    </>
  );
};

export default EndPartnerForm;
