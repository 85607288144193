import { Button, InformationPanel } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { HREF } from "../../../routes/routes";
import { Preferences } from "../../../lib/types";

interface Props {
  preferences: Preferences;
}

const MyDataCorrespondenceDetails: React.FC<Props> = ({ preferences }) => {
  
  const correspondenceDetails = (
    preferences: Preferences,
  ) => {
    return [
      {
        input: <FormattedMessage id={`utils.lang.${preferences.language === "nl_NL" ? "nl" : "en"}`} />,
        label: <FormattedMessage id="my-data-correspondence-settings.language" />,
      },
      {
        input: <FormattedMessage id={`my-data-correspondence-settings.way-of-communication.${preferences.wayOfCommunication}`} />,
        label: <FormattedMessage id="my-data-correspondence-settings.legal" />,
      },
      {
        input: preferences.email,
        label: <FormattedMessage id="utils.email" />,
      },
      {
        input: <FormattedMessage id={`utils.${preferences.isReceiveNews ? "yes" : "no"}`} />,
        label: <FormattedMessage id="my-data-correspondence-settings.newsletter" />,
      },
    ];
  };

  return (
    <InformationPanel
      buttons={
        <Button size="md" text={<FormattedMessage id="utils.edit" />} href={HREF.USER_CORRESPONDENCE_DETAILS_FORM} />
      }
      className="flex-1 h-full"
      header={<FormattedMessage id="my-data-correspondence-settings" />}
      informationDetails={correspondenceDetails(preferences)}
    />
  );
};

export default MyDataCorrespondenceDetails;
