import { Icon, Text } from "@fonk-gitlab/bpfd-portal";
import { ReactNode } from "react";

interface Props {
  body: ReactNode;
}

export const ThankYouMessage: React.FC<Props> = ({ body }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "2rem",
        width: "100%",
        minWidth: "8rem",
      }}>
      <Text
        as="span"
        fontFamily="primary"
        fontStyle="normal"
        size="h1"
        type="primary-lg-button"
        weight="normal"
        className="flex flex-row gap-x-2 items-center">
        <Icon
          color="fill-black"
          hoverColor="hover:fill-black group-hover:fill-black"
          icon="checkmark"
          size="md"
          className="mr-2"
        />
        <div style={{ lineHeight: "1.5rem" }}>{body}</div>
      </Text>
    </div>
  );
};

export default ThankYouMessage;
