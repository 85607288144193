export const isEmptyObject = (object: object | undefined) => {
  if (!object || Array.isArray(object)) return false;
  return Object.keys(object).length === 0;
};

export const objToQueryParams = (obj: object): string => {
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      params.append(key, value);
    }
  });
  return params.toString();
};
