import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useIntlMessage = () => {
  const intl = useIntl();
  return useCallback((id: string): string => intl.formatMessage({ id }), [intl]);
};

export const useIntlMessageInput = () => {
  const intl = useIntl();
  return (id: string, input: Record<string, string>): string => intl.formatMessage({ id }, input);
};

export const useTypeErrorMessage = () => {
  const intl = useIntl();
  return (id: string): { invalid_type_error: string } => ({
    invalid_type_error: intl.formatMessage({ id: id }),
  });
};
