import { FormImportantMessage, IntroPanel } from "@fonk-gitlab/bpfd-portal";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Message, Person, Toggles } from "../../lib/types";
import { getHomepageWarnings } from "../../react-query/queries";
import { HREF } from "../../routes/routes";
import { PortalLanguage } from "../../utils/languageLoader";
import { useHandleAuthError } from "./ErrorHandlingHelper";

const FormattedWarnings = (warnings: Message[], locale: string) => {
  return (
    <ul style={{ marginBottom: "0px" }}>
      {warnings?.map((warning: Message) => {
        const value = locale === PortalLanguage.NL ? warning.textNL : warning.textUS;
        return <li key={value}>{value}</li>;
      })}
    </ul>
  );
};

interface IntroPanelHomeProps {
  user: Person;
  toggles: Toggles;
}

const caseOrder = {
  hasPassingAwayCase: 1,
  hasMissingBankAccountCase: 2,
  hasDivorceCase: 3,
  hasValueTransferCase: 4,
  hasEndEmploymentCase: 5,
  hasRetirementCase: 6,
  enablePlanner: 7,
};
const caseDetails = {
  1: {
    buttonText: <FormattedMessage id="home.passing-away" />,
    hrefLink: HREF.PASSING_AWAY,
    introText: <FormattedMessage id="home.intro-passing-away" />,
  },
  2: {
    buttonText: <FormattedMessage id="home.missing-bank-account" />,
    hrefLink: HREF.BANK_ACCOUNT,
    introText: <FormattedMessage id="home.intro-missing-bank-account" />,
  },
  3: {
    buttonText: <FormattedMessage id="home.divorce" />,
    hrefLink: HREF.SUBMIT_DIVORCE,
    introText: <FormattedMessage id="home.intro-divorce" />,
  },
  4: {
    buttonText: <FormattedMessage id="home.value-transfer" />,
    hrefLink: HREF.VALUE_TRANSFER_APPROVAL,
    introText: <FormattedMessage id="home.intro-value-transfer" />,
  },
  5: {
    buttonText: <FormattedMessage id="home.end-employment" />,
    hrefLink: HREF.PLANNER,
    introText: <FormattedMessage id="home.intro-end-employment" />,
  },
  6: {
    buttonText: <FormattedMessage id="home.retirement" />,
    hrefLink: HREF.RETIREMENT_CHOICE,
    introText: <FormattedMessage id="home.intro-retirement" />,
  },
  7: {
    buttonText: <FormattedMessage id="home.compare-situations" />,
    hrefLink: HREF.PLANNER,
    introText: <FormattedMessage id="home.intro-panel-body" />,
  },
};

const filterUnusedAttributes = (toggles: Toggles) => {
  const {
    isRetireEarlyOrLater: _isRetireEarlyOrLater,
    isStartCohabitation: _isStartCohabitation,
    isRequestValueTransfer: _isRequestValueTransfer,
    isEndCohabitation: _isEndCohabitation,
    ...rest
  } = toggles;
  return rest;
};

const determineCaseOrder = (toggles: Toggles) => {
  if (!toggles) return;
  const filteredToggles = filterUnusedAttributes(toggles);
  const excludedKey = "isExPartner";
  return (
    Object.keys(filteredToggles)
      .filter((key) => key !== excludedKey && toggles[key as keyof Toggles] === true)
      .sort((a, b) => caseOrder[a as keyof typeof caseOrder] - caseOrder[b as keyof typeof caseOrder])[0] ||
    null
  );
};

const IntroPanelHome: React.FC<IntroPanelHomeProps> = ({ user, toggles }) => {
  const { locale } = useIntl();
  const navigate = useNavigate();
  const {
    data: warnings,
    error,
    refetch,
  } = useQuery({
    queryKey: ["warnings"],
    queryFn: getHomepageWarnings,
    select: (data) => data.messages as Message[],
  });

  useHandleAuthError(error, refetch);

  const name = `${user?.firstName.slice(0, 1)}. ${user?.lastName}`;
  const hasWarnings = () => warnings && warnings.length > 0;
  const action = determineCaseOrder(toggles as Toggles);
  const caseKey: number | null = caseOrder[action as keyof typeof caseOrder];

  const greetingText =
    caseKey === 1 ? <FormattedMessage id="utils.dear" /> : <FormattedMessage id="utils.welcome" />;
  let buttonText;
  let hrefLink: HREF;
  let introText = <FormattedMessage id="home.intro-default" />;

  if (!!action && caseKey) {
    const detail = caseDetails[caseKey as keyof typeof caseDetails];
    buttonText = detail.buttonText;
    hrefLink = detail.hrefLink;
    introText =
      toggles?.isExPartner && caseKey === 1 ? (
        <FormattedMessage id="home.intro-passing-away-ex-partner" />
      ) : (
        detail.introText
      );
  }

  const bodyText = hasWarnings() ? "" : introText;
  const handleButtonClick = () => {
    navigate(hrefLink);
  };

  return (
    <IntroPanel
      introductionText={greetingText}
      name={name}
      body={bodyText}
      buttonText={buttonText}
      onButtonClick={handleButtonClick}
      warnings={
        hasWarnings() ? (
          <FormImportantMessage
            heading={<FormattedMessage id="home.intro-panel-warning" />}
            body={FormattedWarnings(warnings || [], locale)}
            className="my-2"
          />
        ) : null
      }
    />
  );
};

export default IntroPanelHome;
