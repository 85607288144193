import { ReactNode } from "react";
import "./GenericTwoColumnTable.css";

type ValueType = string | number | ReactNode;

interface GenericTwoColumnTableProps {
  leftValues: ValueType[];
  rightValues: ValueType[];
  boldLeft?: ValueType[];
  boldRight?: ValueType[];
  paddingLeft?: ValueType[];
}
const GenericTwoColumnTable: React.FC<GenericTwoColumnTableProps> = ({
  leftValues,
  rightValues,
  boldLeft = [],
  boldRight = [],
  paddingLeft = [],
}) => {
  return (
    <div className="row">
      <div className="column">
        {leftValues.map((left, i) => (
          <div
            key={"left_" + i}
            style={{
              fontWeight: boldLeft.includes(left) ? "bold" : "normal",
              lineHeight: "1.5",
              paddingLeft: paddingLeft.includes(left) ? "2.5rem" : "",
            }}>
            {left}
          </div>
        ))}
      </div>
      <div className="column">
        {rightValues.map((right, i) => (
          <div
            key={"right_" + i}
            style={{ fontWeight: boldRight.includes(right) ? "bold" : "normal", lineHeight: "1.5" }}>
            <div>{right}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GenericTwoColumnTable;
