import { CorrespondenceType } from "../lib/enum";
import { Preferences } from "../lib/types";
import { APILanguage } from "./languageLoader";

export const getDefaultPreferences = (): Preferences => ({
  givenName: "",
  isReceiveNews: false,
  isSameAddress: true,
  language: APILanguage.NL,
  personId: "",
  personUUID: "",
  phoneNumber: "",
  startDate: Date.now().toString(),
  wayOfCommunication: CorrespondenceType.POST,
  email: "",
  endDate: null,
  corCountry: null,
  corAddress1: null,
  corAddress2: null,
  corAddress3: null,
});

export const getPreferencesOrDefault = (preferences: Preferences): Preferences => {
  if (!preferences) {
    return getDefaultPreferences();
  }
  return preferences;
};
