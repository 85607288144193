import { Fragment, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, FormImportantMessage, PageLayout, Popup, TextHeader } from "@fonk-gitlab/bpfd-portal";
import PlannerSituation from "../organisms/planner/PlannerSituation";
import PlannerPredictionHeader from "../organisms/planner/PlannerPredictionHeader";
import PlannerOptions from "../organisms/planner/PlannerOptions";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";
import { filterUnusedAttributes, PlannerProvider, usePlanner } from "../../context/PlannerContext";
import { EmploymentAndRetirementRenamed, PlannerCalculatePayload } from "../../lib/types";
import { ParticipationStatus } from "../../lib/enum";
import formatEmploymentToAPI from "../../utils/formatEmploymentToAPI";
import formatRetirementPayload from "../../utils/formatRetirementToAPI";
import formatSituationPayload from "../../utils/formatSituationToAPI";
import { renameKeys } from "../../utils/renameKeys";
import formatScenarioToForm from "../../utils/formatScenarioToForm";
import useScrollTo from "../../hooks/useScrollTo";

const PlannerConsumer: React.FC = () => {
  const { ref, scrollToView } = useScrollTo<HTMLDivElement>();
  const intl = useIntl();
  const {
    planner,
    isLoading,
    calculate: retrieve,
    errorCalculate,
    resetCalculate,
    isPending,
    isCalculateSuccess,
    deleteScenarioAsync,
  } = usePlanner() || {};

  const [isOpenPopup, setIsOpenPopup] = useState<boolean>();
  const [isRetrieved, setIsRetrieved] = useState<boolean>(false);

  const isActive = planner?.participation?.status === ParticipationStatus.ACTIVE;
  const birthDay = planner?.person?.dateOfBirth;

  useEffect(() => {
    if (planner && isOpenPopup === undefined) {
      setIsOpenPopup(!!planner?.scenario);
    }
  }, [isOpenPopup, planner]);

  const handleClosePopup = useCallback(() => {
    setIsOpenPopup(false);
    if (errorCalculate && resetCalculate) {
      resetCalculate();
    }
  }, [errorCalculate, resetCalculate]);

  const handleRetrieve = () => {
    if (planner?.scenario && retrieve) {
      const filteredScenario = filterUnusedAttributes(planner.scenario);
      if (filteredScenario) {
        const formattedData = formatScenarioToForm(
          filteredScenario,
          planner.employment,
          intl,
          planner.retirement
        );
        // map from scenario to submitData
        const renamedKeys = renameKeys(formattedData) as unknown as EmploymentAndRetirementRenamed;
        const payload: PlannerCalculatePayload = {
          hours: Number(planner.employment.hours),
          salary: Number(planner.employment.salary),
          situations: formatSituationPayload(planner?.situations),
          employment: formatEmploymentToAPI(renamedKeys.employment),
          retirement: formatRetirementPayload(renamedKeys.retirement),
        };
        setIsRetrieved(true);
        retrieve(payload);
      }
    }
  };

  const handleDelete = () => {
    if (deleteScenarioAsync) {
      deleteScenarioAsync();
      setIsRetrieved(false);
    }
    handleClosePopup();
  };

  useEffect(() => {
    if (isCalculateSuccess && isOpenPopup) {
      handleClosePopup();
    }
  }, [handleClosePopup, isCalculateSuccess, isOpenPopup]);

  if (isLoading) {
    return <Loading />;
  }

  if (!planner || !birthDay) {
    return <RequestError />;
  }

  return (
    <>
      <PageLayout>
        <Fragment key=".1">
          <TextHeader subText={<FormattedMessage id="planner.description" />} text="" />
          <div className="container py-20 flex-grow">
            <PlannerPredictionHeader isActive={isActive} />
            <div className="grid grid-cols-12 gap-x-1 md:gap-x-2">
              <div className="lg:col-span-5 col-span-12 lg:col-start-2 order-2 lg-order-1 mb-20 lg:mb-0">
                <PlannerOptions isRetrieved={isRetrieved} scrollToView={scrollToView} />
              </div>
              <div ref={ref} className="col-span-12 lg:col-span-5 order-1 lg:order-2 mb-20 lg:mb-0">
                <PlannerSituation />
              </div>
            </div>
          </div>
        </Fragment>
      </PageLayout>
      <Popup
        open={isOpenPopup}
        body={
          <>
            <FormattedMessage id="planner.use-scenario" />
            {errorCalculate && (
              <div style={{ margin: "-2rem", marginLeft: 0, justifySelf: "start" }}>
                <FormImportantMessage
                  body={<FormattedMessage id="request-error" values={{ name: errorCalculate.message }} />}
                  className="mt-10 mb-4 mx-auto"
                />
              </div>
            )}
          </>
        }
        buttons={
          <>
            <Button
              ariaLabel="yes"
              size="md"
              text={<FormattedMessage id="utils.yes" />}
              onClick={handleRetrieve}
              disabled={isPending}
            />
            <Button
              ariaLabel="no"
              size="md"
              text={<FormattedMessage id="utils.no" />}
              onClick={handleDelete}
              disabled={isPending}
            />
          </>
        }
        onClose={handleClosePopup}
        popupVariant="confirmation"
        preventBackdropClick
      />
    </>
  );
};

const Planner = () => {
  return (
    <PlannerProvider>
      <PlannerConsumer />
    </PlannerProvider>
  );
};

export default Planner;
