export const convertFileToBase64 = (file: File): Promise<string | undefined> => {
  if (!(file instanceof File)) Promise.resolve(undefined);

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = (reader.result as string)?.split(",")[1];
      resolve(base64Data);
    };

    reader.onerror = () => {
      reject(new Error("Error reading file"));
    };

    reader.readAsDataURL(file);
  });
};
