import { Button, Popup } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { YesNoValueEnum } from "../../../lib/enum";
import { SubmitData } from "../../forms/RetirementChoiceForm";

interface SurrenderConfirmationPopupProps {
  showPopup: boolean;
  setShowPopup: (showPopup: boolean) => void;
  payload: SubmitData;
  isSubmitting: boolean;
  onClose: (payload?: SubmitData) => void;
}

const SurrenderConfirmationPopup: React.FC<SurrenderConfirmationPopupProps> = ({
  showPopup,
  payload,
  onClose,
  isSubmitting,
}) => {
  const { data, button } = payload;
  const selectedTaxOption =
    data?.taxReductionOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
  const selectedDisabilityOption =
    data?.disabledOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;

  const popupContent = (
    <div className="grid grid-cols-12">
      <span className="col-span-12">{button?.text}</span>
      <table className="col-span-12 w-full pb-4">
        <tr className="text-left">
          <td className="p-2">
            <FormattedMessage id="utils.bank-account" />
          </td>
          <td className="font-medium p-2">{data?.bankAccount?.bankNumber.toString()}</td>
        </tr>
        <tr>
          <td className="p-2">
            <FormattedMessage id="utils.tax-exception" />
          </td>
          <td className="font-medium p-2">
            {selectedTaxOption ? <FormattedMessage id="utils.yes" /> : <FormattedMessage id="utils.no" />}
          </td>
        </tr>
        <tr>
          <td className="p-2">
            <FormattedMessage id="planner.disability" />
          </td>
          <td className="font-medium p-2">
            {selectedDisabilityOption ? (
              <FormattedMessage id="utils.yes" />
            ) : (
              <FormattedMessage id="utils.no" />
            )}
          </td>
        </tr>
      </table>
      <br />
      <span className="col-span-12">
        <FormattedMessage id="retirement-choice.surrender.digi-sign-no" />
      </span>
    </div>
  );

  return (
    <Popup
      open={showPopup}
      heading={<FormattedMessage id="retirement-choice.surrender.confirm-header" />}
      body={popupContent}
      onClose={onClose}
      popupVariant="confirmation"
      buttons={[
        <Button
          key={"cancel"}
          size="md"
          onClick={() => {
            onClose();
          }}
          text={<FormattedMessage id="utils.cancel" />}
          disabled={isSubmitting}
        />,
        <Button
          key={"agree"}
          size="md"
          onClick={() => onClose?.({ data: data, button: button, isSigned: true })}
          text={<FormattedMessage id="utils.agree" />}
          disabled={isSubmitting}
        />,
      ]}
      width={1000}
      preventBackdropClick
    />
  );
};

export default SurrenderConfirmationPopup;
