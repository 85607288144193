import { FormTextBlock, FormField, Input, FormSubmit, Checkbox, Dropdown } from "@fonk-gitlab/bpfd-portal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { updateUserCorrespondenceSettings } from "../../react-query/mutations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../organisms/Loading";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { Preferences } from "../../lib/types";
import { HREF } from "../../routes/routes";
import { UNAUTHORIZED_ERROR_CODE, refreshAuthToken } from "../../utils/token";
import {
  TCorrespondenceAddressDetailsSchema,
  useCorrespondenceAddressDetailsSchema,
} from "../../hooks/schemas/generalSchema";
import { getCommunicationPreferences } from "../../react-query/queries";
import useCountries from "../../hooks/useCountries";
import { useIntlMessage, useIntlMessageInput } from "../../hooks/useIntlMessage";
import { getPreferencesOrDefault } from "../../utils/defaultPreferences";

interface Props {
  defaultValues: DefaultValues;
  userId: string;
  countriesOptions: { value: string; id: string; label: string }[];
}

interface DefaultValues {
  isSameAddress: boolean;
  country: { value: string; id: string; label: string; key: string } | null;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
}

const UserCorrespondenceAddressDetailsFormChild: React.FC<Props> = ({
  defaultValues,
  userId,
  countriesOptions,
}) => {
  const intlMessage = useIntlMessage();
  const intlMessageInput = useIntlMessageInput();
  const { countries } = useCountries();

  const isSameAddressKey = "isSameAddress";
  const countryKey = "country";
  const addressLine1Key = "addressLine1";
  const addressLine2Key = "addressLine2";
  const addressLine3Key = "addressLine3";

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    mutateAsync,
    isPending,
    error: postError,
    reset,
  } = useMutation({
    mutationFn: updateUserCorrespondenceSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["communication", userId],
      });
      navigate(HREF.MY_DATA_TAB_2);
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutateAsync(data);
          }
        });
      }
      setError(isSameAddressKey, { message: postError?.message });
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<TCorrespondenceAddressDetailsSchema>({
    mode: "onChange",
    resolver: zodResolver(useCorrespondenceAddressDetailsSchema()),
    defaultValues: defaultValues,
  });

  const isSameAddress = watch(isSameAddressKey);

  useEffect(() => {
    if (!isSameAddress) {
      return;
    }

    setValue(countryKey, null);
    setValue(addressLine1Key, "");
    setValue(addressLine2Key, "");
    setValue(addressLine3Key, "");
  }, [isSameAddress, setValue]);

  const onSubmit = async (data: FieldValues) => {
    const country = countries.find((country) => country.countryCode === data.country?.value);
    await mutateAsync({
      isSameAddress: data.isSameAddress,
      corCountry: country?.countryNameEN,
      corAddress1: data.addressLine1,
      corAddress2: data.addressLine2,
      corAddress3: data.addressLine3,
    } as Preferences);
    reset();
  };

  return (
    <div className="bg-gradient-mint-blue-light">
      <div className="container flex-grow py-20">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5 md:col-start-4 space-y-4">
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <FormTextBlock heading={<FormattedMessage id="my-data.contact-details" />} />
              <FormField className="col-span-12" id={isSameAddressKey}>
                <Controller
                  name={isSameAddressKey}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      id={isSameAddressKey}
                      checked={value}
                      onChange={onChange}
                      label={<FormattedMessage id="my-data-correspondence-settings.same-address" />}
                      errorSpacing={false}
                    />
                  )}
                />
              </FormField>
              {!isSameAddress && (
                <>
                  <FormField
                    className="col-span-12 mt-10"
                    id="country"
                    labelText={<FormattedMessage id="utils.country" />}>
                    <Dropdown
                      name={"country"}
                      items={countriesOptions}
                      control={control}
                      disabled={isSubmitting || isPending}
                      placeholder={intlMessage("utils.country-select")}
                    />
                  </FormField>
                  <FormField
                    className="col-span-12"
                    id={addressLine1Key}
                    labelText={<FormattedMessage id="utils.address-line" values={{ number: "1" }} />}>
                    <Input
                      id={addressLine1Key}
                      name={addressLine1Key}
                      control={control}
                      disabled={isSubmitting || isPending}
                      error={!!errors.addressLine1}
                      placeholder={intlMessageInput("utils.address-line", { number: "1" })}
                    />
                  </FormField>
                  <FormField
                    className="col-span-12"
                    id={addressLine2Key}
                    labelText={<FormattedMessage id="utils.address-line" values={{ number: "2" }} />}>
                    <Input
                      id={addressLine2Key}
                      name={addressLine2Key}
                      control={control}
                      disabled={isSubmitting || isPending}
                      error={!!errors.addressLine2}
                      placeholder={intlMessageInput("utils.address-line", { number: "2" })}
                    />
                  </FormField>
                  <FormField
                    className="col-span-12"
                    id={addressLine3Key}
                    labelText={<FormattedMessage id="utils.address-line" values={{ number: "3" }} />}>
                    <Input
                      id={addressLine3Key}
                      name={addressLine3Key}
                      control={control}
                      disabled={isSubmitting || isPending}
                      error={!!errors.addressLine3}
                      placeholder={intlMessageInput("utils.address-line", { number: "3" })}
                    />
                  </FormField>
                </>
              )}
              <FormField id="country">
                <FormSubmit
                  buttons={[
                    {
                      ariaLabel: "button",
                      text: <FormattedMessage id="utils.save-changes" />,
                      type: "submit",
                      disabled: !isDirty || isSubmitting || !isValid,
                    },
                    {
                      ariaLabel: "link",
                      onClick: () => {
                        navigate(-1);
                      },
                      text: <FormattedMessage id="utils.cancel" />,
                    },
                  ]}
                  errorMessage={postError?.message}
                />
              </FormField>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserCorrespondenceAddressDetailsForm = () => {
  const data = useAuthentication();
  const { data: preferences, isLoading: comIsLoading } = useQuery({
    queryKey: ["communication", data?.user?.id],
    queryFn: getCommunicationPreferences,
    select: (data) => getPreferencesOrDefault(data.preferences[0] as Preferences),
    enabled: !!data?.user?.id,
  });

  const { countriesByUserLng, countryInUserLngByEnglishName } = useCountries();
  const countriesOptions = useMemo(
    () =>
      countriesByUserLng.map((item) => ({
        value: item.countryCode,
        id: item.countryCode,
        label: item.countryName,
        key: item.countryCode,
      })).sort((a, b) => a.label.localeCompare(b.label)),
    [countriesByUserLng]
  );

  const userCountry = countryInUserLngByEnglishName(preferences?.corCountry ?? "")?.countryName;

  const defaultValues: DefaultValues = {
    isSameAddress: preferences?.isSameAddress ?? true,
    country:
      preferences?.corCountry !== undefined
        ? (countriesOptions.find((country) => country.label === userCountry) ?? null)
        : null,
    addressLine1: preferences?.corAddress1 ?? "",
    addressLine2: preferences?.corAddress2 ?? "",
    addressLine3: preferences?.corAddress3 ?? "",
  };

  if (data?.isLoading || preferences === undefined || comIsLoading) {
    return <Loading />;
  }

  return (
    <UserCorrespondenceAddressDetailsFormChild
      defaultValues={defaultValues}
      userId={data?.user?.id ?? ""}
      countriesOptions={countriesOptions}
    />
  );
};

export default UserCorrespondenceAddressDetailsForm;
