import { IntlShape } from "react-intl";
import { YesNoValueEnum } from "../lib/enum";
import { YesNoRadioOptions } from "../lib/types";

const yesNoOptions = (
  intl: IntlShape,
  prefix: string,
  defaultChecked: { yes: boolean; no: boolean }
): YesNoRadioOptions => {
  return [
    {
      label: intl.formatMessage({ id: "utils.yes" }),
      checked: defaultChecked.yes,
      value: YesNoValueEnum.YES,
      id: `${prefix}-yes`,
    },
    {
      label: intl.formatMessage({ id: "utils.no" }),
      checked: defaultChecked.no,
      value: YesNoValueEnum.NO,
      id: `${prefix}-no`,
    },
  ];
};

export default yesNoOptions;
