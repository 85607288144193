import { Person, Preferences } from "../../../lib/types";
import MyDataCorrespondenceDetails from "../../molecules/myData/MyDataCorrespondenceDetails";
import MyDataCorrespondenceAddressDetails from "../../molecules/myData/MyDataCorrespondenceAddressDetails";

interface Props {
  preferences: Preferences;
  user: Person;
}

const MyDataSecondTab: React.FC<Props> = ({ preferences, user }) => {
  const tailwindClasses = "col-span-12 lg:col-span-5 lg:odd:col-start-2";

  return (
    <>
      <div className={tailwindClasses}>
        <MyDataCorrespondenceDetails preferences={preferences} />
      </div>
      <div className={tailwindClasses}>
        <MyDataCorrespondenceAddressDetails preferences={preferences} user={user} />        
      </div>
    </>
  );
};

export default MyDataSecondTab;
