import { InformationPanel, Button } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { Person } from "../../../lib/types";
import { MaritalStatus } from "../../../lib/enum";
import { HREF } from "../../../routes/routes";
import { reverseDate } from "../../../utils/reverseDate";
import { useAuthentication } from "../../../context/AuthenticatedContext";

interface MyDataUserDetailsProps {
  user: Person;
}

const MyDataUserDetails: React.FC<MyDataUserDetailsProps> = ({ user }) => {
  const { toggles } = useAuthentication() || {};

  const userDetails = (user: Person) => {
    const lastNameWithPrefix = user?.middleName ? user.middleName + " " + user.lastName : user.lastName;

    const getMaritalStatus = (maritalStatus: MaritalStatus) => {
      switch (maritalStatus) {
        case MaritalStatus.CHILD:
          return <FormattedMessage id="utils.marital-status.child" />;
        case MaritalStatus.COHABITATION:
          return <FormattedMessage id="utils.marital-status.cohabitation" />;
        case MaritalStatus.MARRIAGE:
          return <FormattedMessage id="utils.marital-status.marriage" />;
        case MaritalStatus.PARTNERSHIP:
          return <FormattedMessage id="utils.marital-status.partnership" />;
        default:
          return <FormattedMessage id="utils.marital-status.single" />;
      }
    };

    return [
      {
        label: <FormattedMessage id="my-data.relation-id" />,
        input: user.personId,
      },
      {
        label: <FormattedMessage id="utils.BSN" />,
        input: user.bsn,
      },
      {
        label: <FormattedMessage id="utils.lastName" />,
        input: lastNameWithPrefix,
      },
      {
        label: <FormattedMessage id="utils.firstName" />,
        input: user.firstName,
      },
      {
        label: <FormattedMessage id="utils.givenName" />,
        input: user.givenName,
      },
      {
        label: <FormattedMessage id="utils.marital-status" />,
        input: getMaritalStatus(user.maritalStatus),
      },
      {
        label: <FormattedMessage id="utils.birthdate" />,
        input: reverseDate(user.dateOfBirth),
      },
      {
        label: <FormattedMessage id="utils.gender" />,
        input: <FormattedMessage id={`utils.gender.${user.gender}`} />,
      },
    ];
  };

  return (
    <InformationPanel
      buttons={[
        <Button
          key="edit-button"
          size="md"
          text={<FormattedMessage id="utils.edit" />}
          href={HREF.USER_DETAILS_FORM}
        />,
        ...(toggles?.isStartCohabitation
          ? [
              <Button
                key="add-partner-button"
                size="md"
                text={<FormattedMessage id="utils.add.partner" />}
                href={HREF.PARTNER_DETAILS_FORM}
              />,
            ]
          : []),
      ]}
      className="flex-1 h-full"
      header={<FormattedMessage id="my-data.my-data-tab" />}
      informationDetails={userDetails(user)}
    />
  );
};

export default MyDataUserDetails;
