import { Button, InformationPanel } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { HREF } from "../../../routes/routes";
import { Person, Preferences } from "../../../lib/types";
import useCountries from "../../../hooks/useCountries";

interface Props {
  preferences: Preferences;
  user: Person;
}

const MyDataCorrespondenceAddressDetails: React.FC<Props> = ({ preferences, user }) => {
  const { countryInUserLngByEnglishName } = useCountries();

  const correspondenceAddressDetailsBrp = (
    user: Person
  ) => {
    return [
      {
        label: <FormattedMessage id="utils.address" />,
        input: `${user.brpStreetName ?? ""} ${user.brpHouseNumber ?? ""} ${user.brpHouseNumberSuffix ?? ""}`,
      },
      {
        label: <FormattedMessage id="utils.postal-code" />,
        input: user.brpPostalCode,
      },
      {
        label: <FormattedMessage id="utils.city" />,
        input: user.brpCityName,
      },
      {
        label: <FormattedMessage id="utils.country" />,
        input: user.brpCountry,
      }
    ];
  };

  const correspondenceAddressDetailsCustom = (
    preferences: Preferences
  ) => {
    return [
      {
        label: <FormattedMessage id="utils.address-line" values={{"number": "1"}} />,
        input: preferences.corAddress1,
      },
      {
        label: <FormattedMessage id="utils.address-line" values={{"number": "2"}} />,
        input: preferences.corAddress2,
      },
      {
        label: <FormattedMessage id="utils.address-line" values={{"number": "3"}} />,
        input: preferences.corAddress3,
      },
      {
        label: <FormattedMessage id="utils.country" />,
        input: countryInUserLngByEnglishName(preferences.corCountry)?.countryName ?? ""
      }
    ];
  }

  const correspondenceAddressDetails = preferences.isSameAddress
    ? correspondenceAddressDetailsBrp(user)
    : correspondenceAddressDetailsCustom(preferences);

  return (
    <InformationPanel
      buttons={
        <Button size="md" text={<FormattedMessage id="utils.edit" />} href={HREF.USER_CORRESPONDENCE_ADDRESS_DETAILS_FORM} />
      }
      className="flex-1 h-full"
      header={<FormattedMessage id="my-data-correspondence-address" />}
      informationDetails={correspondenceAddressDetails}
    />
  );
};

export default MyDataCorrespondenceAddressDetails;
