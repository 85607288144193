import { FormField, FormSubmit, FormTextBlock, Popup } from "@fonk-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { PaymentOptionValue } from "../../constants/passingAway";
import useCreateChoicesText from "../../hooks/plannerStartupScreen/useCreateChoicesText";
import {
  TRetirementChoiceSchema,
  useRetirementChoiceSchema,
} from "../../hooks/schemas/retirementChoiceSchema";
import useCountries from "../../hooks/useCountries";
import { ButtonAction, ButtonType, RetirementChoiceScreen, YesNoValueEnum } from "../../lib/enum";
import { ButtonConfig, RetirementChoice, RetirementChoiceScreenPayload } from "../../lib/types";
import { deleteScenario, processRetirementChoices } from "../../react-query/mutations";
import { HREF } from "../../routes/routes";
import { isEmptyObject } from "../../utils/objectUtils";
import { retiringSoonResourceLink } from "../../utils/pensionLinks";
import { removeSpace } from "../../utils/string";
import { refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../../utils/token";
import yesNoOptions from "../../utils/YesNoOptions";
import YesNoRadio from "../atoms/YesNoRadio";
import SurrenderConfirmationPopup from "../molecules/retirementChoice/SurrenderConfirmationPopup";
import BankAccountForm from "./BankAccountForm";

interface Props {
  retirementChoice: RetirementChoice;
}

export interface SubmitData {
  data: TRetirementChoiceSchema;
  button: ButtonConfig;
  isSigned: boolean;
}

const RetirementChoiceForm = ({ retirementChoice }: Props) => {
  const buttons = useCreateChoicesText(retirementChoice);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const intl = useIntl();

  const textButtons = buttons.filter((item) => item.type === ButtonType.BUTTON);
  const submitButtons = buttons.filter((item) => item.type === ButtonType.SUBMIT);
  const { person, screen } = retirementChoice;
  const haveRelationship = person?.hasRelationship;
  const hasTaxException = person?.hasTaxException;
  const isDisabled = person?.isDisabled;
  const hasBankAccount = !!person?.personalBankAccount;
  const isInsideBankAccount = !hasBankAccount || person?.personalBankAccount?.isBankAccountEU;
  const { countriesByUserLng } = useCountries();
  const countriesOptions = countriesByUserLng.map((item) => ({
    value: item.countryCode,
    id: item.countryCode,
    label: item.countryName,
  }));
  const paymentOptions = [
    {
      label: intl.formatMessage({ id: "passing-away.within-eu" }),
      checked: !!isInsideBankAccount,
      value: PaymentOptionValue.InsideEU,
      id: "1",
    },
    {
      label: intl.formatMessage({ id: "passing-away.outside-eu" }),
      checked: !isInsideBankAccount,
      value: PaymentOptionValue.OutsideEU,
      id: "2",
    },
  ];

  const havingPartnerOptions = yesNoOptions(intl, "partner", {
    yes: haveRelationship,
    no: !haveRelationship,
  });
  const taxReductionOptions = yesNoOptions(intl, "tax", { yes: hasTaxException, no: !hasTaxException });
  const disabledOptions = yesNoOptions(intl, "disabled", { yes: hasTaxException, no: !hasTaxException });
  const existingCountryCode = person?.personalBankAccount?.countryCode ?? 0;
  const existingBankLand = !person?.personalBankAccount?.isBankAccountEU
    ? countriesOptions.find((country) => country.value === existingCountryCode.toString())
    : undefined;
  const bankAccount = {
    paymentOptions: paymentOptions,
    bankNumber: person?.personalBankAccount?.bankAccount ?? "",
    bankName: person?.personalBankAccount?.bankName ?? "",
    bankCode: person?.personalBankAccount?.bankBicCode ?? "",
    bankAddress: person?.personalBankAccount?.bankAddress ?? "",
    bankLocation: person?.personalBankAccount?.locationBank ?? "",
    bankLand: existingBankLand,
  };
  const retirementChoiceSchema = useRetirementChoiceSchema();
  const { watch, control, setValue, handleSubmit } = useForm<TRetirementChoiceSchema>({
    mode: "onChange",
    defaultValues: {
      bankAccount: bankAccount,
      havingPartnerOptions,
      taxReductionOptions,
      disabledOptions,
    },
    resolver: zodResolver(retirementChoiceSchema),
  });

  const [watchPaymentOptions, watchPartnerOptions, watchDisabledOptions] = watch([
    "bankAccount.paymentOptions",
    "havingPartnerOptions",
    "disabledOptions",
  ]);

  const isInsideEU = watchPaymentOptions
    ? watchPaymentOptions.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU
    : isInsideBankAccount;
  const showCohabitationLink =
    watchPartnerOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
  const disabledYes = watchDisabledOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
  const upsertChoiceButtons = [
    ButtonAction.UPSERT_CHOICE_PENSION, // 6
    ButtonAction.UPSERT_CHOICE_AOW, // 7
    ButtonAction.UPSERT_CHOICE_DEFAULT, // 8
    ButtonAction.UPSERT_CHOICE, // 16
  ];
  const surrenderChoiceButtons = [
    ButtonAction.SURRENDER_CHOICE_PENSION, // 10
    ButtonAction.SURRENDER_CHOICE_AOW, // 11
    ButtonAction.SURRENDER_CHOICE_DEFAULT, // 12
  ];
  const deleteChoiceButtons = [ButtonAction.DELETE_CHOICE, ButtonAction.DELETE_CHOICE_SURRENDER]; // 9, 13

  useEffect(() => {
    if (existingBankLand) {
      setValue("bankAccount.bankLand", existingBankLand);
    }
  }, [existingBankLand, setValue]);

  const [showMoreAboutTax, setShowMoreAboutTax] = useState(false);
  const [showMoreAboutTaxDetails, setShowMoreAboutTaxDetails] = useState(false);
  const [selectedButtonId, setSelectedButtonId] = useState(0);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [payload, setPayload] = useState<SubmitData>({
    data: {} as TRetirementChoiceSchema,
    button: {} as ButtonConfig,
    isSigned: false,
  });

  const onClickTaxIcon = () => {
    setShowMoreAboutTax((prevState) => !prevState);
  };

  const onClickTaxInfoIcon = () => {
    setShowMoreAboutTaxDetails((prevState) => !prevState);
  };

  const {
    mutate: mutateRetirementChoices,
    error: postError,
    isPending,
    reset,
  } = useMutation({
    mutationFn: processRetirementChoices,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["toggles"],
      });
      if (surrenderChoiceButtons.includes(selectedButtonId)) {
        setShowConfirmationPopup(false);
        setShowThankYouMessage(true);
        await queryClient.invalidateQueries({
          queryKey: ["choices"],
        });
        reset();
        return;
      }
      navigate(HREF.PLANNER);
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutateRetirementChoices(data);
          }
        });
      }
    },
  });

  const { mutate: mutateDeleteScenario, isPending: isPendingDelete } = useMutation({
    mutationFn: deleteScenario,
    onSuccess: () => {
      queryClient.removeQueries({ queryKey: ["planner"], exact: true });
      navigate(HREF.PLANNER);
    },
    onError: async (postError): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutateDeleteScenario();
          }
        });
      }
    },
  });

  const handleAgreedButton = (surrenderPayload?: SubmitData) => {
    if (!isEmptyObject(surrenderPayload) && surrenderPayload?.isSigned) {
      handleSubmit(() => onSubmit(surrenderPayload))();
      return;
    }
    setShowConfirmationPopup(false);
  };

  const currentScenarioAction = (action: ButtonAction) => {
    if (action === ButtonAction.GO_TO_PLANNER) {
      navigate(HREF.PLANNER);
    }
    if (action === ButtonAction.DELETE_SCENARIO) {
      mutateDeleteScenario();
    }
  };

  const onSubmit = (submitData: SubmitData) => {
    const { data, button, isSigned } = submitData;
    const selectedButton = button?.action;
    setSelectedButtonId(selectedButton);

    if (!isSigned && surrenderChoiceButtons.includes(selectedButton)) {
      setPayload(submitData);
      setShowConfirmationPopup(true);
      return;
    }

    const selectedBankAccountType =
      data?.bankAccount?.paymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU;
    const selectedTaxOption =
      data?.taxReductionOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;

    let action = "";
    if (selectedButton === ButtonAction.UPDATE_CHOICE) {
      action = "UPDATE_CHOICE";
    }
    if (upsertChoiceButtons.includes(selectedButton)) {
      action = "UPSERT_NEW_CHOICE";
    }
    if (deleteChoiceButtons.includes(selectedButton)) {
      action = "DELETE_CHOICE";
    }
    if (surrenderChoiceButtons.includes(selectedButton) && isSigned) {
      action = "PROCESS_SURRENDER_CHOICE";
    }

    let retirementDate = null;
    if (
      selectedButton === ButtonAction.UPSERT_CHOICE_PENSION ||
      selectedButton === ButtonAction.SURRENDER_CHOICE_PENSION
    ) {
      retirementDate = retirementChoice?.participation.pensionRetirementDate;
    }
    if (
      selectedButton === ButtonAction.UPSERT_CHOICE_AOW ||
      selectedButton === ButtonAction.SURRENDER_CHOICE_AOW
    ) {
      retirementDate = retirementChoice?.participation.aowRetirementDate;
    }
    if (
      selectedButton === ButtonAction.UPSERT_CHOICE_DEFAULT ||
      selectedButton === ButtonAction.SURRENDER_CHOICE_DEFAULT
    ) {
      retirementDate = retirementChoice?.participation.defaultRetirementDate;
    }
    if (selectedButton === ButtonAction.UPSERT_CHOICE) {
      retirementDate =
        retirementChoice?.entitlementChoice?.partlyRetirementStartDate ??
        retirementChoice?.entitlementChoice?.retirementStartDate;
    }

    const payload: RetirementChoiceScreenPayload = {
      action: action,
      personalBankAccount: {
        isBankAccountEU: selectedBankAccountType,
        bankAccount: removeSpace(data?.bankAccount?.bankNumber),
        bankName: data?.bankAccount?.bankName,
        bankBicCode: data?.bankAccount?.bankCode,
        bankAddress: data?.bankAccount?.bankAddress,
        locationBank: data?.bankAccount?.bankLocation,
        countryName: data?.bankAccount?.bankLand?.label,
        countryCode: data?.bankAccount?.bankLand?.value,
      },
      isTaxException: selectedTaxOption,
      retirementDate: retirementDate,
    };
    mutateRetirementChoices(payload);
  };

  return (
    <div className="grid grid-cols-12 md:pt-24 md:pb-24">
      <div className="md:col-start-4 col-span-6">
        <form>
          {retirementChoice?.screen !== RetirementChoiceScreen.CURRENT_SCENARIO && (
            <>
              {!haveRelationship && (
                <FormField
                  id="havingPartnerOptions"
                  labelText={<FormattedMessage id="planner.complete-quest.partner-question" />}>
                  <YesNoRadio name="havingPartnerOptions" options={havingPartnerOptions} control={control} />
                </FormField>
              )}
              {!haveRelationship && showCohabitationLink && (
                <FormTextBlock
                  body={
                    <FormattedMessage
                      id="planner.complete-quest.register-partners"
                      values={{
                        registerLink: (
                          <Link to={HREF.MY_DATA} target="_blank">
                            <FormattedMessage id="planner.complete-quest.alt-text" />
                          </Link>
                        ),
                      }}
                    />
                  }
                />
              )}
              <BankAccountForm
                name="bankAccount"
                control={control as unknown as Control<FieldValues>}
                isInsideEU={isInsideEU as boolean}
              />
              <FormField
                id="taxReductionOptions"
                labelText={<FormattedMessage id="planner.complete-quest.tax-question" />}
                showIcon={true}
                onIconClick={onClickTaxIcon}>
                <YesNoRadio name="taxReductionOptions" options={taxReductionOptions} control={control} />
              </FormField>
              <Popup
                open={showMoreAboutTax}
                body={
                  <FormattedMessage
                    id="planner.complete-quest.tax-reduction-info"
                    values={{
                      link: (
                        <Link to={retiringSoonResourceLink} target="_blank">
                          <FormattedMessage id="planner.exchange.altText-link" />
                        </Link>
                      ),
                    }}
                  />
                }
                onClose={onClickTaxIcon}
                popupVariant="information"
              />
              {isDisabled && (
                <FormField
                  id="disabledOptions"
                  labelText={<FormattedMessage id="planner.complete-quest.disability-question" />}>
                  <YesNoRadio name="disabledOptions" options={disabledOptions} control={control} />
                </FormField>
              )}
              {isDisabled && disabledYes && retirementChoice?.providerPhoneNumber && (
                <FormTextBlock
                  body={
                    <FormattedMessage
                      id="planner.complete-quest.contact-info"
                      values={{ phoneNumber: retirementChoice?.providerPhoneNumber }}
                    />
                  }
                />
              )}
              <FormTextBlock
                body={<FormattedMessage id="planner.complete-quest.eligible-tax" />}
                showIcon={true}
                onIconClick={onClickTaxInfoIcon}
              />
              <Popup
                open={showMoreAboutTaxDetails}
                body={<FormattedMessage id="planner.complete-quest.eligible-tax-info" />}
                onClose={onClickTaxInfoIcon}
                popupVariant="information"
              />
            </>
          )}
          {screen !== RetirementChoiceScreen.ENTITLEMENT_CHOICE && (
            <FormTextBlock body={<FormattedMessage id="retirement-choice.choices" />} />
          )}
          {textButtons.map((item) => (
            <span key={item.action}>{item.text}</span>
          ))}
          <FormSubmit
            className="border-none w-full"
            buttons={submitButtons?.map((item) => ({
              size: "sm",
              ariaLabel: "button",
              id: item?.action,
              text: item?.text,
              type: item.type,
              onClick: (event) => {
                event?.preventDefault();
                currentScenarioAction(item?.action);
                if (![ButtonAction.GO_TO_PLANNER, ButtonAction.DELETE_SCENARIO].includes(item?.action)) {
                  handleSubmit((data) => onSubmit({ data: data, button: item, isSigned: false }))();
                }
              },
              disabled: isPending || isPendingDelete,
            }))}
            errorMessage={postError?.message}
          />
        </form>
      </div>
      <SurrenderConfirmationPopup
        showPopup={showConfirmationPopup}
        setShowPopup={setShowConfirmationPopup}
        payload={payload}
        onClose={handleAgreedButton}
        isSubmitting={isPending}
      />
      <Popup
        open={showThankYouMessage}
        body={<FormattedMessage id="retirement-choice.surrender.thank-you" />}
        onClose={() => navigate(HREF.HOME)}
        popupVariant="information"
      />
    </div>
  );
};

export default RetirementChoiceForm;
