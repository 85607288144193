import { InformationPanel } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { Person } from "../../../lib/types";
import { MaritalStatus } from "../../../lib/enum";
import { reverseDate } from "../../../utils/reverseDate";

interface MyDataPartnerDetailsProps {
  user: Person;
}

const MyDataPartnerDetails: React.FC<MyDataPartnerDetailsProps> = ({ user }) => {
  const maritalStatus = user.maritalStatus;

  const relationshipID = (maritalStatus: MaritalStatus) => {
    if (maritalStatus === MaritalStatus.PARTNERSHIP) {
      return "my-data.registered-partnership";
    } else {
      return "my-data.relationship";
    }
  };

  const partnerDetails = (user: Person) => {
    const lastNameWithPrefix = user?.partnerMiddleName
      ? user.partnerMiddleName + " " + user.partnerLastName
      : user.partnerLastName;

    return [
      {
        label: <FormattedMessage id="utils.lastName" />,
        input: lastNameWithPrefix,
      },
      {
        label: <FormattedMessage id="utils.firstName" />,
        input: user.partnerFirstName,
      },
      {
        label: <FormattedMessage id="utils.birthdate" />,
        input: reverseDate(user.partnerDateOfBirth),
      },
      {
        label: <FormattedMessage id="utils.gender" />,
        input: <FormattedMessage id={`utils.gender.${user.partnerGender}`} />,
      },
      {
        label: <FormattedMessage id="utils.BSN" />,
        input: user.partnerBsn,
      },
      {
        label: <FormattedMessage id={`${relationshipID(maritalStatus)}`} />,
        input: reverseDate(user.relationshipStartDate),
      },
    ];
  };

  return (
    <InformationPanel
      className="flex-1 h-full"
      header={<FormattedMessage id="my-data.partner" />}
      informationDetails={partnerDetails(user)}
    />
  );
};

export default MyDataPartnerDetails;
