import { DataOverview, DataOverviewPanel, InfoToggle } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { numberToMonth } from "../../../utils/numberToMonth";
import { RetiredPension } from "../../../lib/types";
import { useState } from "react";
import { retiredPensionValue } from "../../../utils/pensionValue";
import { format } from "../../../utils/formatNumber";

interface PensionOverviewRetiredProps {
  hasRetiredPension: boolean;
  retiredPension: RetiredPension;
}

export const PensionOverviewRetired: React.FC<PensionOverviewRetiredProps> = ({
  hasRetiredPension,
  retiredPension,
}) => {
  const [isGrossToggle, setIsGrossToggle] = useState(true);

  const dayPeriod = hasRetiredPension ? retiredPension.dayPeriod : 0;
  const monthPeriod = hasRetiredPension ? retiredPension.monthPeriod : 0;

  const handleToggleGrossChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsGrossToggle(event.target.checked);
  };

  return (
    <DataOverviewPanel
      dataOverviews={[
        <DataOverview
          key="0"
          badgeTitle={<FormattedMessage id="home.pension-paid" />}
          badges={[
            {
              label: <FormattedMessage id={`utils.month.${numberToMonth(monthPeriod)}`} />,
              value: dayPeriod.toString(),
            },
          ]}
          subtitle={<FormattedMessage id="home.pension-date" />}
        />,
        <DataOverview
          key="1"
          badgeTitle={<FormattedMessage id={`home.${"attainable"}`} />}
          badges={[
            {
              label: <FormattedMessage id={`utils.monthly-${isGrossToggle ? "gross" : "net"}`} />,
              value: format(retiredPensionValue(retiredPension, isGrossToggle)),
            },
          ]}
          subtitle={<FormattedMessage id={`home.${"old-pension"}`} />}
        />,
      ]}
      infoToggles={[
        <InfoToggle
          key="2"
          icon="information"
          id="gross-toggle"
          onChange={handleToggleGrossChange}
          label={<FormattedMessage id="utils.monthly-gross" />}
          enabled={isGrossToggle}
        />,
      ]}
    />
  );
};
