import {
  FormTextBlock,
  FormField,
  PageLayout,
  SearchDropdown,
  Input,
  FormSubmit,
  FormImportantMessage,
} from "@fonk-gitlab/bpfd-portal";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { Fragment, useEffect } from "react";
import { getProviders, getValueTransfer } from "../../../react-query/queries";
import { useHandleAuthError } from "../../organisms/ErrorHandlingHelper";
import { processValueTransfer } from "../../../react-query/mutations";
import { UNAUTHORIZED_ERROR_CODE, refreshAuthToken } from "../../../utils/token";
import ThankYouMessage from "../../atoms/ThankYouMessage";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { TValueTransferSchema, useValueTransferSchema } from "../../../hooks/schemas/generalSchema";
import Loading from "../../organisms/Loading";
import RequestError from "../../organisms/RequestError";
import { zodResolver } from "@hookform/resolvers/zod";
import BackButton from "../../atoms/BackButton";

interface ProviderDropdown {
  label: string;
  value: string;
}

export interface Provider {
  providerId: string;
  providerName: string;
}

const ValueTransferPage: React.FC = () => {
  const [providersList, setProvidersList] = React.useState<ProviderDropdown[]>([]);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = React.useState<boolean>(false);
  const {
    data: providers,
    error: providerError,
    isLoading: isProviderLoading,
    refetch: refetchProviders,
  } = useQuery({
    queryKey: ["providers"],
    queryFn: getProviders,
    select: (data) => data.providers,
  });

  const {
    data: valueTransfer,
    error: valueTransferError,
    isLoading: isValueTransferLoading,
    refetch: refetchValueTransfer,
  } = useQuery({
    queryKey: ["valueTransfer"],
    queryFn: getValueTransfer,
    select: (data) => data.valueTransfer,
  });

  const isIVT = !!valueTransfer?.isValueTransferAllowed;
  const transferCases = valueTransfer?.valueTransferCases;

  useHandleAuthError(providerError, refetchProviders);
  useHandleAuthError(valueTransferError, refetchValueTransfer);

  const {
    mutateAsync: submitValueTransfer,
    isPending: isSubmitPending,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useMutation({
    mutationFn: processValueTransfer,
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return submitValueTransfer(data);
          }
        });
      }
    },
  });

  const { handleSubmit, control } = useForm<TValueTransferSchema>({
    mode: "onChange",
    resolver: zodResolver(useValueTransferSchema(providers)),
    defaultValues: {
      providerId: "",
      contractNumber: "",
    },
  });

  const onSubmit = async (data: TValueTransferSchema) => {
    const isPendingCase = !!transferCases?.find(
      (item: TValueTransferSchema) =>
        item.contractNumber === data.contractNumber && item.providerId === data.providerId
    );

    if (!isPendingCase) {
      await submitValueTransfer(data);
    }
    if (!isPendingCase && isAlreadySubmitted) {
      setIsAlreadySubmitted(false);
    }
    if (isPendingCase && !isAlreadySubmitted) {
      setIsAlreadySubmitted(true);
    }
  };

  useEffect(() => {
    if (providers && providersList.length === 0) {
      setProvidersList(
        providers.map((provider: Provider) => ({
          label: provider.providerName,
          value: provider.providerId,
        }))
      );
    }
  }, [providers, providersList.length]);

  if (isProviderLoading || isValueTransferLoading) {
    return <Loading />;
  }

  if (providerError || valueTransferError) {
    return <RequestError />;
  }
  return (
    <PageLayout>
      <Fragment key=".0">
        <div className="container flex-grow py-20">
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-5 md:col-start-4 space-y-4">
              {submitError && (
                <FormImportantMessage
                  className="flex w-full pb-4 mb-8"
                  body={<FormattedMessage id="value-transfer.error-message" />}
                />
              )}
              {isAlreadySubmitted && (
                <FormImportantMessage
                  className="flex w-full pb-4 mb-8"
                  body={<FormattedMessage id="value-transfer.already-submitted" />}
                />
              )}
              {isSubmitSuccess && (
                <>
                  <ThankYouMessage
                    body={
                      <FormTextBlock
                        heading={<FormattedMessage id="value-transfer.title" />}
                        body={<FormattedMessage id="value-transfer.thank-you" />}
                      />
                    }
                  />
                  <BackButton />
                </>
              )}
              {!isSubmitSuccess && (
                <form onSubmit={handleSubmit((data: TValueTransferSchema) => onSubmit(data))}>
                  {isIVT ? (
                    <FormTextBlock
                      heading={<FormattedMessage id="value-transfer.title" />}
                      body={<FormattedMessage id="value-transfer.text" />}
                      className=""
                    />
                  ) : (
                    <FormImportantMessage
                      className="flex w-full pb-4 mb-8"
                      body={<FormattedMessage id="value-transfer.not-allowed" />}
                    />
                  )}
                  <FormField
                    labelText={<FormattedMessage id="value-transfer.name-pension-fund" />}
                    id="NaamPensioenfonds">
                    <SearchDropdown id="provider" items={providersList} name="providerId" control={control} />
                  </FormField>
                  <FormField
                    labelText={<FormattedMessage id="value-transfer.contract-number" />}
                    id="ContractNummer">
                    <Input id="contractNumber" name="contractNumber" control={control} />
                  </FormField>
                  <FormSubmit
                    buttons={[
                      {
                        ariaLabel: "submit",
                        className: "",
                        text: <FormattedMessage id="utils.submit" />,
                        type: "submit",
                        disabled: isSubmitPending,
                      },
                    ]}
                  />
                </form>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    </PageLayout>
  );
};

export default ValueTransferPage;
