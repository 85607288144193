import { DataOverview, DataOverviewPanel, InfoToggle, Popup } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { NonRetiredPension } from "../../../lib/types";
import { useState } from "react";
import { nonRetiredPensionValue } from "../../../utils/pensionValue";
import { format } from "../../../utils/formatNumber";

interface PensionOverviewNonRetiredProps {
  hasNonRetiredPension: boolean;
  nonRetiredPension: NonRetiredPension;
}

export const PensionOverviewNonRetired: React.FC<PensionOverviewNonRetiredProps> = ({
  hasNonRetiredPension,
  nonRetiredPension,
}) => {
  const [aOWInfo, setAOWInfo] = useState(false);
  const [opgebouwdInfo, setOpgebouwdInfo] = useState(false);
  const [isAowToggle, setIsAowToggle] = useState(false);
  const [isOpgebouwdToggle, setIsOpgebouwdToggle] = useState(true);
  const [isGrossToggle, setIsGrossToggle] = useState(true);

  const aowRetirementYears = hasNonRetiredPension ? nonRetiredPension.aowRetirementYears : 0;
  const aowRetirementMonths = hasNonRetiredPension ? nonRetiredPension.aowRetirementMonths : 0;
  const defaultRetirementYears = hasNonRetiredPension ? nonRetiredPension.defaultRetirementYears : 0;
  const defaultRetirementMonths = hasNonRetiredPension ? nonRetiredPension.defaultRetirementMonths : 0;

  const AOWMonthsTotal = aowRetirementYears * 12 + aowRetirementMonths;
  const defaultRetirementMonthsTotal = defaultRetirementYears * 12 + defaultRetirementMonths;
  const isAOWGtRetirementValue =
    Boolean(AOWMonthsTotal >= defaultRetirementMonthsTotal) && isAowToggle && !isOpgebouwdToggle;
  const renderedYears = isAOWGtRetirementValue ? aowRetirementYears : defaultRetirementYears;
  const renderedMonths = isAOWGtRetirementValue ? aowRetirementMonths : defaultRetirementMonths;

  const handleAOWPopup = () => {
    setAOWInfo(!aOWInfo);
  };

  const handleOpgebouwdInfo = () => {
    setOpgebouwdInfo(!opgebouwdInfo);
  };

  const handleToggleGrossChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsGrossToggle(event.target.checked);
  };

  const handleOpgebouwdToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsAowToggle(false);
    }
    setIsOpgebouwdToggle(event.target.checked);
  };

  const handleAowToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setIsOpgebouwdToggle(false);
    }
    setIsAowToggle(event.target.checked);
  };

  return (
    <>
      <DataOverviewPanel
        dataOverviews={[
          <DataOverview
            key="0"
            badgeTitle={<FormattedMessage id="home.retire" />}
            badges={[
              {
                label: <FormattedMessage id="utils.year" />,
                value: renderedYears.toString(),
              },
              {
                label: <FormattedMessage id="utils.months" />,
                value: renderedMonths.toString(),
              },
            ]}
            subtitle={<FormattedMessage id="home.pension-from" />}
          />,
          <DataOverview
            key="1"
            badgeTitle={<FormattedMessage id={`home.${isOpgebouwdToggle ? "up-to-now" : "attainable"}`} />}
            badges={[
              {
                label: (
                  <FormattedMessage
                    id={`utils.monthly-${isOpgebouwdToggle || isGrossToggle ? "gross" : "net"}`}
                  />
                ),
                value: format(
                  nonRetiredPensionValue(nonRetiredPension, isOpgebouwdToggle, isAowToggle, isGrossToggle)
                ),
              },
            ]}
            subtitle={<FormattedMessage id={`home.${isOpgebouwdToggle ? "accumulated" : "old-pension"}`} />}
          />,
        ]}
        infoToggles={[
          <InfoToggle
            key="0"
            icon="information"
            iconVisible
            onIconClick={handleAOWPopup}
            id="aow-toggle"
            onChange={handleAowToggle}
            label={<FormattedMessage id="home.including-aow" />}
            enabled={isAowToggle}
          />,
          <InfoToggle
            key="1"
            icon="information"
            iconVisible
            onIconClick={handleOpgebouwdInfo}
            id="opgebouwd-toggle"
            onChange={handleOpgebouwdToggle}
            label={<FormattedMessage id="home.built-up-to-now" />}
            enabled={isOpgebouwdToggle}
          />,
          !isOpgebouwdToggle && (
            <InfoToggle
              key="2"
              icon="information"
              id="gross-toggle"
              onChange={handleToggleGrossChange}
              label={<FormattedMessage id="utils.monthly-gross" />}
              enabled={isGrossToggle}
              alignToggleLeft
            />
          ),
        ]}
      />
      <Popup
        open={aOWInfo}
        body={<FormattedMessage id="home.including-aow-info" />}
        heading={<FormattedMessage id="home.including-aow" />}
        onClose={handleAOWPopup}
        popupVariant="information"
      />
      <Popup
        open={opgebouwdInfo}
        body={<FormattedMessage id="home.built-up-to-now-info" />}
        heading={<FormattedMessage id="home.built-up-to-now" />}
        onClose={handleOpgebouwdInfo}
        popupVariant="information"
      />
    </>
  );
};
