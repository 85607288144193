import { InformationPanel } from "@fonk-gitlab/bpfd-portal";
import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Gender } from "../../../lib/enum";
import { Person } from "../../../lib/types";

interface DivorcePersonProps {
  personInfo: Person;
  headerPanel: ReactNode;
}

const PersonInfo = ({ personInfo, headerPanel }: DivorcePersonProps) => {
  const intl = useIntl();
  const gender = personInfo?.gender || Gender.UNKNOWN;
  return (
    <InformationPanel
      className="flex-1 h-full"
      header={headerPanel}
      informationDetails={[
        {
          input: personInfo?.name,
          label: <FormattedMessage id="utils.name" />,
        },
        {
          input: personInfo?.bsn,
          label: <FormattedMessage id="utils.bsn" />,
        },
        {
          input: <FormattedMessage id={`utils.gender.${gender}`} />,
          label: <FormattedMessage id="utils.gender" />,
        },
        {
          input: personInfo?.phone,
          label: <FormattedMessage id="utils.phone" />,
        },
        {
          input: intl.formatDate(personInfo?.birthdate ?? ""),
          label: <FormattedMessage id="utils.birthdate" />,
        },
        {
          input: personInfo?.email,
          label: <FormattedMessage id="utils.email" />,
        },
      ]}
    />
  );
};

export default PersonInfo;
