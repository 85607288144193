import {
  FormField,
  FormPopup,
  FormTextBlock,
  Multistep,
  MultistepButtonCollection,
  MultistepPanel,
  RadioButtons,
} from "@fonk-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { PaymentOptionValue } from "../../../constants/passingAway";
import { usePlanner } from "../../../context/PlannerContext";
import {
  TPlannerAdditionalQuestSchema,
  usePlannerAdditionalQuestSchema,
} from "../../../hooks/schemas/plannerAdditionalQuestSchema";
import useCountries from "../../../hooks/useCountries";
import { YesNoValueEnum } from "../../../lib/enum";
import { RetirementChoices } from "../../../lib/types";
import { HREF } from "../../../routes/routes";
import { retiringSoonResourceLink } from "../../../utils/pensionLinks";
import { getUuid } from "../../../utils/uuid";
import yesNoOptions from "../../../utils/YesNoOptions";
import BankAccountForm from "../../forms/BankAccountForm";

interface AdditionalQuestionsProps {
  showPopup: boolean;
  onClose: (payload?: RetirementChoices) => void;
}

const AdditionalQuestions: React.FC<AdditionalQuestionsProps> = ({ showPopup, onClose }) => {
  const { relation, person, employment } = usePlanner()?.planner ?? {};
  const { countriesByUserLng } = useCountries();
  const countriesOptions = countriesByUserLng.map((item) => ({
    value: item.countryCode,
    id: item.countryCode,
    label: item.countryName,
  }));
  const intl = useIntl();
  const hasBankAccount = !!person?.personalBankAccount;
  const isInsideBankAccount = !hasBankAccount || person?.personalBankAccount?.isBankAccountEU;
  const bankAccountTypes = [
    {
      label: intl.formatMessage({ id: "passing-away.within-eu" }),
      checked: !!isInsideBankAccount,
      value: PaymentOptionValue.InsideEU,
      id: "1",
    },
    {
      label: intl.formatMessage({ id: "passing-away.outside-eu" }),
      checked: !isInsideBankAccount,
      value: PaymentOptionValue.OutsideEU,
      id: "2",
    },
  ];

  const havingPartnerOptions = yesNoOptions(intl, "partner", {
    yes: !!relation?.partnerId,
    no: !relation?.partnerId,
  });
  const taxReductionOptions = yesNoOptions(intl, "tax", {
    yes: !!person?.hasTaxException,
    no: !person?.hasTaxException,
  });
  const disabledOptions = yesNoOptions(intl, "disabled", {
    yes: !!person?.disabled,
    no: !person?.disabled,
  });

  const existingCountryCode = person?.personalBankAccount?.countryCode ?? 0;
  const existingBankLand = !person?.personalBankAccount?.isBankAccountEU
    ? countriesOptions.find((country) => country.value === existingCountryCode.toString())
    : undefined;

  const plannerAdditionalQuestSchema = usePlannerAdditionalQuestSchema();
  const {
    control,
    watch,
    handleSubmit,
    trigger,
    setValue,
    formState: { isSubmitting },
  } = useForm<TPlannerAdditionalQuestSchema>({
    mode: "onChange",
    defaultValues: {
      bankAccount: {
        paymentOptions: bankAccountTypes,
        bankNumber: person?.personalBankAccount?.bankAccount ?? "",
        bankName: person?.personalBankAccount?.bankName ?? "",
        bankCode: person?.personalBankAccount?.bankBicCode ?? "",
        bankAddress: person?.personalBankAccount?.bankAddress ?? "",
        bankLocation: person?.personalBankAccount?.locationBank ?? "",
        bankLand: existingBankLand,
      },
      havingPartnerOptions,
      taxReductionOptions,
      disabledOptions,
    },
    resolver: zodResolver(plannerAdditionalQuestSchema),
  });

  const [watchBankAccountTypes, watchPartnerOptions, watchDisabledOptions] = watch([
    "bankAccount.paymentOptions",
    "havingPartnerOptions",
    "disabledOptions",
  ]);

  const isInsideEU =
    watchBankAccountTypes !== null
      ? watchBankAccountTypes.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU
      : isInsideBankAccount;
  const showCohabitationLink =
    watchPartnerOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
  const disabledYes = watchDisabledOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;

  const [currentStep, setCurrentStep] = useState(1);
  const [taxInfo, setTaxInfo] = useState(false);
  const [eligibleTaxInfo, setEligibleTaxInfo] = useState(false);
  const [disabledInfo, setDisabledInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleStepClick = (step: number) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    if (existingBankLand) {
      setValue("bankAccount.bankLand", existingBankLand);
    }
  }, [existingBankLand, setValue]);

  const validateBankAccountInfo = async () => {
    return await Promise.all([trigger("bankAccount")]);
  };

  const onSubmit = async (data: TPlannerAdditionalQuestSchema) => {
    const selectedBankAccountType =
      data?.bankAccount?.paymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU;
    const selectedTaxOption =
      data?.taxReductionOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
    const disabledOption = data?.disabledOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
    const selectedCountry = data?.bankAccount?.bankLand?.value;
    const additionalPayload: RetirementChoices = {
      bankAccount: {
        isBankAccountEU: selectedBankAccountType,
        bankAccountNumber: data?.bankAccount?.bankNumber,
        bankName: data?.bankAccount?.bankName,
        bankBicCode: data?.bankAccount?.bankCode,
        bankAddress: data?.bankAccount?.bankAddress,
        bankLocation: data?.bankAccount?.bankLocation,
        bankCountryName: selectedCountry,
      },
      personId: getUuid() ?? "",
      taxExemption: selectedTaxOption,
      disabled: disabledOption,
      partnerId: relation?.partnerId ?? null,
    };
    onClose?.(additionalPayload);
  };

  return (
    <FormPopup open={showPopup} onClose={onClose} preventBackdropClick>
      <form>
        <Multistep
          steps={[
            { label: <FormattedMessage id="planner.complete-quest.intro" />, index: 1 },
            { label: <FormattedMessage id="utils.question" values={{ number: 1 }} />, index: 2 },
            { label: <FormattedMessage id="utils.question" values={{ number: 2 }} />, index: 3 },
            { label: <FormattedMessage id="utils.question" values={{ number: 3 }} />, index: 4 },
            { label: <FormattedMessage id="utils.question" values={{ number: 4 }} />, index: 5 },
          ]}
          interactive
          onStepClick={handleStepClick}
          currentStep={currentStep}>
          <MultistepPanel tabIndex={1}>
            <FormTextBlock
              body={<FormattedMessage id="planner.complete-quest.popup-intro" />}
              heading={<FormattedMessage id="planner.complete-quest.popup-heading" />}
            />
          </MultistepPanel>
          <MultistepPanel tabIndex={2}>
            <FormField
              id="havingPartnerOptions"
              labelText={<FormattedMessage id="planner.complete-quest.partner-question" />}>
              <RadioButtons name="havingPartnerOptions" options={havingPartnerOptions} control={control} />
            </FormField>
            {showCohabitationLink && (
              <FormTextBlock
                body={
                  <FormattedMessage
                    id="planner.complete-quest.register-partners"
                    values={{
                      registerLink: (
                        <Link to={HREF.MY_DATA} target="_blank">
                          <FormattedMessage id="planner.complete-quest.alt-text" />
                        </Link>
                      ),
                    }}
                  />
                }
              />
            )}
          </MultistepPanel>
          <MultistepPanel tabIndex={3}>
            <BankAccountForm
              name="bankAccount"
              control={control as unknown as Control<FieldValues>}
              isInsideEU={isInsideEU as boolean}
            />
          </MultistepPanel>
          <MultistepPanel tabIndex={4}>
            <FormField
              id="taxReductionOptions"
              labelText={<FormattedMessage id="planner.complete-quest.tax-question" />}
              showIcon
              onIconClick={() => setTaxInfo((prev) => !prev)}>
              <RadioButtons name="taxReductionOptions" options={taxReductionOptions} control={control} />
            </FormField>
            {taxInfo && (
              <FormTextBlock
                body={
                  <FormattedMessage
                    id="planner.complete-quest.tax-reduction-info"
                    values={{
                      link: (
                        <Link to={retiringSoonResourceLink} target="_blank">
                          <FormattedMessage id="planner.exchange.altText-link" />
                        </Link>
                      ),
                    }}
                  />
                }
              />
            )}
            <FormTextBlock
              body={<FormattedMessage id="planner.complete-quest.eligible-tax" />}
              showIcon
              onIconClick={() => setEligibleTaxInfo((prev) => !prev)}
            />
            {eligibleTaxInfo && (
              <FormTextBlock body={<FormattedMessage id="planner.complete-quest.eligible-tax-info" />} />
            )}
          </MultistepPanel>
          <MultistepPanel tabIndex={5}>
            <FormField
              id="disabledOptions"
              labelText={<FormattedMessage id="planner.complete-quest.disability-question" />}
              showIcon
              onIconClick={() => setDisabledInfo((prev) => !prev)}>
              <RadioButtons name="disabledOptions" options={disabledOptions} control={control} />
            </FormField>
            {disabledYes && (
              <FormTextBlock
                body={
                  <FormattedMessage
                    id="planner.complete-quest.contact-info"
                    values={{ phoneNumber: employment?.providerParticipantPhoneNumber }}
                  />
                }
              />
            )}
            {disabledInfo && (
              <FormTextBlock
                body={
                  <FormattedMessage id="planner.complete-quest.disability-info" values={{ br: <br /> }} />
                }
              />
            )}
          </MultistepPanel>
          <MultistepButtonCollection
            buttons={{
              backButton: {
                onClick: () => setCurrentStep((step) => step - 1),
                text: <FormattedMessage id="utils.multistep.back" />,
                type: "button",
              },
              forwardButton: {
                onClick: () => setCurrentStep((step) => step + 1),
                text: <FormattedMessage id="utils.multistep.forward" />,
                type: "button",
              },
              startButton: {
                onClick: () => setCurrentStep((step) => step + 1),
                text: <FormattedMessage id="utils.multistep.start" />,
                type: "button",
              },
              completeButton: {
                text: <FormattedMessage id="planner.complete-quest.continue-to-sign" />,
                type: "submit",
                disabled: isSubmitting,
                onClick: (event) => {
                  event?.preventDefault();
                  (async () => {
                    const validData = await validateBankAccountInfo();
                    if (!validData[0]) {
                      setErrorMessage(true);
                      return;
                    }
                    setErrorMessage(false);
                    handleSubmit((data) => onSubmit(data))();
                  })();
                },
              },
            }}
          />
          {errorMessage && (
            <div className="text-right text-red">
              <FormattedMessage id="validation.bank-account" />
            </div>
          )}
        </Multistep>
      </form>
    </FormPopup>
  );
};

export default AdditionalQuestions;
