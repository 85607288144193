import { z } from "zod";
import { PaymentOptionValue } from "../../constants/passingAway";
import {
  BANK_ACCOUNT_REGEX,
  BANK_ACCOUNT_SPECIAL_CHARACTERS,
  BANK_ADDRESS_REGEX,
  BLOCK_HTML,
  BLOCK_SQL,
} from "../../lib/constants";
import { validateIBAN } from "../../utils/iban";
import { removeSpace } from "../../utils/string";
import { useIntlMessage } from "../useIntlMessage";

const option = z.object({ value: z.string(), checked: z.boolean() });

export const useBankAccountSchema = () => {
  const intlMessage = useIntlMessage();

  const noSQLAndBlockHTMLString = z
    .string()
    .refine((value: string) => !BLOCK_SQL.test(value), intlMessage("validation.special-characters"))
    .refine((value: string) => !BLOCK_HTML.test(value), intlMessage("validation.special-characters"));

  function getSchema() {
    return z
      .object({
        paymentOptions: z.array(option).nullable(),
        bankNumber: noSQLAndBlockHTMLString.transform((value) => removeSpace(value)),
        bankName: noSQLAndBlockHTMLString
          .refine(
            (value: string) => BANK_ACCOUNT_SPECIAL_CHARACTERS.test(value),
            intlMessage("validation.special-characters")
          )
          .optional(),
        bankCode: noSQLAndBlockHTMLString
          .refine(
            (value: string) => BANK_ACCOUNT_REGEX.test(value),
            intlMessage("validation.special-characters")
          )
          .optional(),
        bankAddress: noSQLAndBlockHTMLString
          .refine(
            (value: string) => BANK_ADDRESS_REGEX.test(value),
            intlMessage("validation.special-characters")
          )
          .optional(),
        bankLocation: noSQLAndBlockHTMLString
          .refine(
            (value: string) => BANK_ADDRESS_REGEX.test(value),
            intlMessage("validation.special-characters")
          )
          .optional(),
        bankLand: z.object({ label: z.string(), value: z.string() }).optional(),
      })
      .refine(
        (bankAccount) => {
          const isInsideEU =
            bankAccount.paymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU;

          return isInsideEU
            ? validateIBAN(bankAccount.bankNumber)
            : BANK_ACCOUNT_REGEX.test(bankAccount.bankNumber);
        },
        {
          message: intlMessage("validation.bankNumber.invalid"),
          path: ["bankNumber"],
        }
      )
      .superRefine((bankAccount, ctx) => {
        const isOutsideEU =
          bankAccount.paymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.OutsideEU;

        if (!isOutsideEU) return;

        if (!bankAccount?.bankNumber) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankNumber"],
          });
        }

        if (!bankAccount?.bankName) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankName"],
          });
        }
        if (!bankAccount?.bankCode) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankCode"],
          });
        }
        if (!bankAccount?.bankAddress) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankAddress"],
          });
        }
        if (!bankAccount?.bankLocation) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankLocation"],
          });
        }
        if (!bankAccount?.bankLand) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: intlMessage("validation.required"),
            path: ["bankLand"],
          });
        }
      });
  }

  return getSchema;
};
