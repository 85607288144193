import {
  CustomDocument,
  OutstandingResponse,
  ValidatePersonRequest,
  ValidatePersonResponse,
} from "../lib/types";
import { objToQueryParams } from "../utils/objectUtils";
import { getAuthToken, isTokenExpired, refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../utils/token";
import { getUuid } from "../utils/uuid";

const baseUrl = window.env.VITE_API_BASE_URL;
const getHeaders = () => ({
  Authorization: "Bearer " + getAuthToken() || "",
  Accept: "application/json",
  "Content-Type": "application/json",
});

const get = async (endpoint: string, altBaseUrl?: string) => {
  const response = await fetch((altBaseUrl ?? baseUrl) + endpoint, {
    method: "GET",
    headers: getHeaders(),
  });
  const result = await response.json();

  if (response.status === 401) {
    throw new Error(UNAUTHORIZED_ERROR_CODE);
  }

  if (!response.ok) {
    throw new Error(result?.message ?? "An error has occurred while processing the request.", {
      cause: {
        raw: response,
        body: result,
      },
    });
  }

  return result;
};

const getWithRefresh = async (endpoint: string) => {
  if (isTokenExpired()) {
    await refreshAuthToken().then((response) => {
      if (response?.accessToken) {
        return get(endpoint);
      }
    });
  }
  return get(endpoint);
};

export const getUser = () => getWithRefresh(`persons/${getUuid()}`);

export const getPension = () => getWithRefresh(`persons/${getUuid()}/pensions`);

export const getCommunicationPreferences = () => getWithRefresh(`persons/${getUuid()}/preferences`);

export const getDocuments = () =>
  getWithRefresh(`persons/${getUuid()}/documents`).then((data) =>
    data.documents.sort(
      (a: CustomDocument, b: CustomDocument) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    )
  );

export const getHomepageWarnings = () => get(`persons/${getUuid()}/messages`);

export const getPlanner = () => get(`persons/${getUuid()}/planner`);

export const getToggles = () => get(`persons/${getUuid()}/toggles`);

export const getSurvivors = () => get(`survivors/${getUuid()}`);

export const getCountries = () => get(`countries`);

export const getOutstandingRequests = () =>
  getWithRefresh(`persons/${getUuid()}/cases`).then((data) => data as OutstandingResponse);

export const getRetirementChoice = () => get(`persons/${getUuid()}/retirement/choices`);

export const getValueTransfer = () => get(`/persons/${getUuid()}/valuetransfer`);

export const getProviders = () => get(`providers`);

export const validateBsn = (data: ValidatePersonRequest): Promise<ValidatePersonResponse> =>
  get(`persons/search?${objToQueryParams(data)}`);

export const getDivorce = () => get(`/persons/${getUuid()}/divorce`);
