import { FormTextBlock, FormField, Input, FormSubmit } from "@fonk-gitlab/bpfd-portal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { FieldValues, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateUserContactDetails } from "../../react-query/mutations";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../organisms/Loading";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { Preferences } from "../../lib/types";
import { HREF } from "../../routes/routes";
import { UNAUTHORIZED_ERROR_CODE, refreshAuthToken } from "../../utils/token";
import { TPhoneSchema, usePhoneSchema } from "../../hooks/schemas/generalSchema";

export const ContactDetailsForm = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const data = useAuthentication();
  const user = data?.user;

  const {
    mutateAsync,
    isPending,
    error: postError,
    reset,
  } = useMutation({
    mutationFn: updateUserContactDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
      navigate(HREF.MY_DATA);
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutateAsync(data);
          }
        });
      }
      setError("phoneNumber", { message: postError?.message });
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<TPhoneSchema>({
    mode: "onChange",
    resolver: zodResolver(usePhoneSchema()),
    defaultValues: {
      phoneNumber: user?.phoneNumber,
    },
  });

  useEffect(() => {
    setValue("phoneNumber", user?.phoneNumber ?? "");
  }, [user, setValue]);

  const onSubmit = async (data: FieldValues) => {
    await mutateAsync({ phoneNumber: data.phoneNumber } as Preferences);
    reset();
  };

  if (data?.isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-gradient-mint-blue-light">
      <div className="container flex-grow py-20">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5 md:col-start-4 space-y-4">
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <FormTextBlock heading={<FormattedMessage id="my-data.contact-details" />} />

              <FormField id="phoneNumber" labelText={<FormattedMessage id="utils.phone" />}>
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  control={control}
                  disabled={isSubmitting || isPending}
                  error={!!errors.phoneNumber}
                />
              </FormField>

              <FormField id="phoneNumber">
                <FormSubmit
                  buttons={[
                    {
                      size: "md",
                      ariaLabel: "button",
                      text: <FormattedMessage id="utils.save-changes" />,
                      type: "submit",
                      disabled: !isDirty || isSubmitting || !isValid,
                    },
                    {
                      size: "md",
                      ariaLabel: "link",
                      onClick: () => {
                        navigate(-1);
                      },
                      text: <FormattedMessage id="utils.cancel" />,
                    },
                  ]}
                  errorMessage={postError?.message}
                />
              </FormField>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
