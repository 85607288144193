import React from "react";
import { format, parseISO, subYears, isBefore } from "date-fns";
import {
  DatePicker,
  Dropdown,
  DropdownItem,
  FormField,
  FormImportantMessage,
  FormSubmit,
  Input,
  Popup,
  Text,
} from "@fonk-gitlab/bpfd-portal";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { PartnerDetail, usePartnerDetailSchema } from "../../../hooks/schemas/partnerSchema";
import { DATE_FORMAT, NETHERLANDS_BRP } from "../../../lib/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthentication } from "../../../context/AuthenticatedContext";
import { validateBsn } from "../../../react-query/queries";
import { useMutation, useQuery } from "@tanstack/react-query";
import useGenderOption from "../../../hooks/useGenderOption";
import PartnerDetailPreView from "../../organisms/partner/PartnerDetailPreview";
import { updateCohabitation } from "../../../react-query/mutations";
import { useNavigate } from "react-router-dom";
import { HREF } from "../../../routes/routes";
import { useIntlMessage } from "../../../hooks/useIntlMessage";
import { refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../../../utils/token";
import SpinningWheel from "../../atoms/SpinningWheel";

export const DEFAULT_DROPDOWN_ITEM: DropdownItem = {
  id: "",
  label: "",
  value: "",
};

export const PARTNER_DETAILS_FORM: PartnerDetail = {
  firstName: "",
  lastName: "",
  middleName: "",
  gender: "",
  partnerBsn: "",
  partnerDateOfBirth: undefined,
  startDate: undefined,
};

const PartnerForm = () => {
  const navigate = useNavigate();
  const intlMessage = useIntlMessage();
  const { user, refetchToggles, refetchUser } = useAuthentication() || {};
  const [preSubmit, setPreSubmit] = React.useState<boolean>(false);
  const [popup, setPopup] = React.useState<boolean>(false);
  const [bsnError, setBsnError] = React.useState<string>("");
  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    setValue,
    handleSubmit,
    watch,
    reset,
  } = useForm<PartnerDetail>({
    resolver: zodResolver(usePartnerDetailSchema(user?.brpCountry ?? "", user?.bsn ?? "")),
    defaultValues: PARTNER_DETAILS_FORM,
  });
  const { startDate, partnerDateOfBirth, gender, partnerBsn, firstName, lastName, middleName } = watch();
  const { error, refetch } = useQuery({
    queryKey: ["validate_user"],
    queryFn: () => {
      if (partnerDateOfBirth) {
        return validateBsn({
          bsn: partnerBsn,
          gender,
          dateOfBirth: format(partnerDateOfBirth, DATE_FORMAT.YYYYMMDD),
        });
      }
    },
    select: (data) => data?.person,
    enabled: false,
  });
  const {
    mutateAsync: mutateCohabitation,
    isPending,
    mutate,
  } = useMutation({
    mutationFn: updateCohabitation,
    onSuccess: () => {
      setPopup(true);
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutate(data);
          }
        });
      }
      if ((postError.cause as { message: string })?.message === "MESSAGE_INVALID_VALUE_BSN") {
        setBsnError(intlMessage("validation.bsn.invalid"));
      } else {
        setBsnError(intlMessage("error.request"));
      }
    },
  });
  const country = user?.brpCountry ?? "";
  const gender_list: DropdownItem[] = useGenderOption();
  const isNotNetherlands = country && !NETHERLANDS_BRP.includes(country);
  const isBsnError = error?.cause && (error?.cause as { raw: Response; body: object }).raw.status === 410;
  const warningStartDate: string =
    startDate && isBefore(startDate, subYears(new Date(), 1))
      ? intlMessage("validation.more-than-a-year")
      : "";

  const handleDate = (name: keyof PartnerDetail, value: unknown) => {
    setValue(name, value as Date, {
      shouldDirty: true,
    });
  };

  const handleForm = (name: keyof PartnerDetail, value: string) => {
    setValue(name, value, {
      shouldDirty: true,
    });
  };

  const handleSubmitValue = async (_: PartnerDetail) => {
    if (isNotNetherlands) return setPreSubmit(true);

    const result = await refetch();
    if (result.status === "success") {
      const { data } = result;
      reset(
        {
          firstName: data?.firstName ?? "",
          middleName: data?.middleName ?? "",
          lastName: data?.lastName ?? "",
          gender: data?.gender ?? "",
          partnerBsn: data?.bsn ?? "",
          partnerDateOfBirth: data?.dateOfBirth ? parseISO(data?.dateOfBirth) : undefined,
          startDate,
        },
        {
          keepDirty: true,
        }
      );
      setPreSubmit(true);
    } else {
      setPreSubmit(false);
    }
  };

  const handleCancelPreview = () => {
    setPreSubmit(false);
  };

  const handleUpdateCohabitation = () => {
    mutateCohabitation({
      partnerBsn,
      partnerDateOfBirth: format(partnerDateOfBirth as Date, DATE_FORMAT.YYYYMMDD),
      startDate: format(startDate as Date, DATE_FORMAT.YYYYMMDD),
      partnerGender: gender,
    });
  };

  const handleClosePopup = () => {
    setPopup(false);
    refetchToggles?.();
    refetchUser?.();
    navigate(HREF.ACTIONS);
  };

  const errors_messages = React.useMemo<Record<keyof PartnerDetail, string>>(() => {
    return {
      startDate: errors.startDate?.message ?? "",
      gender: errors.gender?.message ?? "",
      partnerBsn: errors.partnerBsn?.message ?? "",
      partnerDateOfBirth: errors.partnerDateOfBirth?.message ?? "",
      firstName: errors.firstName?.message ?? "",
      lastName: errors.lastName?.message ?? "",
      middleName: "",
    };
  }, [
    errors?.firstName,
    errors?.lastName,
    errors?.startDate,
    errors?.gender,
    errors?.partnerBsn,
    errors?.partnerDateOfBirth,
  ]);

  if (isSubmitting) {
    return <SpinningWheel />;
  }

  return (
    <>
      {error && (
        <FormImportantMessage
          className="mb-6"
          body={<FormattedMessage id={`error.${isBsnError ? "bsn-message" : "request"}`} />}
          heading={<FormattedMessage id="error.validation-fail.title" />}
        />
      )}
      {preSubmit ? (
        <PartnerDetailPreView
          firstName={firstName ?? ""}
          lastName={lastName ?? ""}
          middleName={middleName ?? ""}
          bsn={partnerBsn}
          gender={gender}
          dob={partnerDateOfBirth ? format(partnerDateOfBirth, DATE_FORMAT.YYYYMMDD) : ""}
          startDate={startDate ? format(startDate, DATE_FORMAT.YYYYMMDD) : ""}
          loading={isPending}
          error={bsnError}
          onCancel={handleCancelPreview}
          onSubmit={() => handleUpdateCohabitation()}
        />
      ) : (
        <form onSubmit={handleSubmit(handleSubmitValue)}>
          {isNotNetherlands && (
            <>
              <FormField id="lastName" labelText={<FormattedMessage id="utils.lastName" />}>
                <Input id="lastName" name="lastName" control={control} error={!!errors?.lastName?.message} />
              </FormField>
              <FormField id="firstName" labelText={<FormattedMessage id="utils.firstName" />}>
                <Input
                  id="firstName"
                  name="firstName"
                  control={control}
                  error={!!errors?.firstName?.message}
                />
              </FormField>
            </>
          )}
          <FormField id="partnerDateOfBirth" labelText={<FormattedMessage id="utils.birthdate" />}>
            <DatePicker
              name="partnerDateOfBirth"
              placeholder={intlMessage("utils.select.date.placeholder")}
              ariaLabelIconButton="Icon date picker"
              value={partnerDateOfBirth as string | undefined}
              feedbackMessage={errors_messages.partnerDateOfBirth}
              error={!!errors?.partnerDateOfBirth}
              onChange={(value) => handleDate("partnerDateOfBirth", value)}
            />
          </FormField>
          <FormField id="gender" labelText={<FormattedMessage id="utils.gender" />}>
            <Dropdown
              name="gender"
              value={
                gender.length > 0 ? gender_list.find((item) => item.value === gender) : DEFAULT_DROPDOWN_ITEM
              }
              items={gender_list}
              feedbackMessage={errors_messages.gender}
              onSelected={(item) => handleForm("gender", item.value as string)}
              error={!!errors?.gender?.message}
            />
          </FormField>
          <FormField id="partnerBsn" labelText="BSN">
            <div
              style={{
                marginBottom: "12px",
              }}>
              <Text size="xs">
                <FormattedMessage id="partner.bsn-quotation" />
              </Text>
            </div>
            <Input
              id="partnerBsn"
              name="partnerBsn"
              control={control}
              error={!!errors?.partnerBsn?.message}
            />
          </FormField>

          <FormField id="startDate" labelText={<FormattedMessage id="partner.start.date" />}>
            <div className="mb-4 text-red">
              {warningStartDate.length > 0 && <Text size="xs">{warningStartDate}</Text>}
            </div>
            <DatePicker
              name="startDate"
              ariaLabelIconButton="Icon date picker"
              placeholder={intlMessage("utils.select.date.placeholder")}
              value={startDate as string | undefined}
              feedbackMessage={errors_messages.startDate}
              onChange={(value) => handleDate("startDate", value)}
              error={!!errors.startDate}
            />
          </FormField>
          <FormField id="submit">
            <FormSubmit
              buttons={[
                {
                  size: "md",
                  ariaLabel: "link",
                  onClick: () => {
                    navigate(-1);
                  },
                  text: <FormattedMessage id="utils.cancel" />,
                },
                {
                  size: "md",
                  ariaLabel: "button",
                  text: <FormattedMessage id="utils.save-changes" />,
                  type: "submit",
                  disabled: !isDirty || isSubmitting,
                },
              ]}
            />
          </FormField>
        </form>
      )}
      <Popup
        ariaLabelCloseIcon="Sluiten"
        body={<FormattedMessage id="partner-detail.cohabitation.confirm" />}
        popupVariant="information"
        open={popup}
        onClose={handleClosePopup}
      />
    </>
  );
};

export default PartnerForm;
