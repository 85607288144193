import PartnerForm from "../forms/partnerform/PartnerForm";
import { FormTextBlock } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { HREF } from "../../routes/routes";
import EndPartnerForm, { EndPartnerFormProps } from "../forms/partnerform/EndPartnerForm";
import Loading from "../organisms/Loading";

const PartnerDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { toggles, user, isLoading } = useAuthentication() || {};
  const isAuthorized: boolean =
    (toggles && (toggles.isEndCohabitation || toggles.isStartCohabitation)) ?? true;
  const baseUser: EndPartnerFormProps = {
    firstName: user?.partnerFirstName ?? "",
    lastName: user?.partnerLastName ?? "",
    middleName: user?.partnerMiddleName ?? "",
    bsn: user?.partnerBsn ?? "",
    dob: user?.partnerDateOfBirth ?? "",
    gender: user?.partnerGender ?? "",
    startDate: user?.relationshipStartDate ?? "",
  };

  if (!isAuthorized) {
    navigate(HREF.NOTFOUND);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-gradient-mint-blue-light">
      <div className="container flex-grow py-20">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5 md:col-start-4 space-y-4">
            <FormTextBlock heading={<FormattedMessage id="page-partner.title" />} />
            {toggles?.isEndCohabitation ? <EndPartnerForm {...baseUser} /> : <PartnerForm />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerDetailPage;
