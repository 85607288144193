import { RadioButtons, RadioButtonsProps } from "@fonk-gitlab/bpfd-portal";
import { FieldValues, UseControllerProps } from "react-hook-form";
import { useIntl } from "react-intl";
import { YesNoRadioOptions } from "../../lib/types";
import yesNoOptions from "../../utils/YesNoOptions";

export interface YesNoProps<T extends FieldValues>
  extends Omit<RadioButtonsProps, "options">,
    UseControllerProps<T> {
  defaultChecked?: { yes: boolean; no: boolean };
  options?: YesNoRadioOptions;
}

const YesNoRadio = <T extends FieldValues>({
  name,
  options,
  defaultChecked = { yes: false, no: false },
  ...rest
}: YesNoProps<T>) => {
  const intl = useIntl();
  return <RadioButtons {...rest} name={name} options={options || yesNoOptions(intl, name, defaultChecked)} />;
};

export default YesNoRadio;
