import {
  DatePicker,
  Dropdown,
  FormField,
  FormSubmit,
  FormTextBlock,
  IconButton,
  Input,
  Text,
} from "@fonk-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Control, Controller, FieldValues, useFieldArray, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Fragment } from "react/jsx-runtime";
import { PaymentOptionValue, WZPValue } from "../../constants/passingAway";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { TPassingAwaySchema, usePassingAwaySchema } from "../../hooks/schemas/passingAwaySchema";
import { useIntlMessage } from "../../hooks/useIntlMessage";
import { YesNoValueEnum } from "../../lib/enum";
import { Survivor, SurvivorPayload, YesNoRadioOption } from "../../lib/types";
import { updateSurvivor } from "../../react-query/mutations";
import { formatDate } from "../../utils/date";
import { reverseDate } from "../../utils/reverseDate";
import { removeSpace } from "../../utils/string";
import { refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../../utils/token";
import yesNoOptions from "../../utils/YesNoOptions";
import YesNoRadio from "../atoms/YesNoRadio";
import BankAccountForm from "./BankAccountForm";

interface Props {
  survivor: Survivor;
  onSuccess: () => void;
}

const PassingAwayForm = ({ survivor, onSuccess }: Props) => {
  const { toggles } = useAuthentication() || {};

  const intlMessage = useIntlMessage();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [bsnError, setBsnError] = useState<string | undefined>();

  const {
    isOrphan: isChild,
    isAdult,
    children: registeredChildren,
    isWzp2025Apply,
    isSicknessBenefit,
    isEligible,
  } = survivor;

  const genderItems = survivor.genders
    ? Object.keys(survivor.genders).map((key) => {
        const value = survivor.genders[key as keyof typeof survivor.genders];
        return {
          id: value,
          value: value,
          label: intlMessage(`utils.gender.${value}`),
        };
      })
    : [];
  const paymentOptions = [
    {
      label: intlMessage("passing-away.within-eu"),
      checked: true,
      value: PaymentOptionValue.InsideEU,
      id: "1",
    },
    {
      label: intlMessage("passing-away.outside-eu"),
      checked: false,
      value: PaymentOptionValue.OutsideEU,
      id: "2",
    },
  ];
  const wageTaxOptions = yesNoOptions(intl, "tax", { yes: survivor.isTaxCheck, no: !survivor.isTaxCheck });
  const awnOptions = yesNoOptions(intl, "awn", { yes: false, no: true });
  const childrenOptions = yesNoOptions(intl, "children", { yes: false, no: true });
  const hasBankAccount = !!survivor?.bankAccount;
  const passingAwaySchema = usePassingAwaySchema(hasBankAccount);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<TPassingAwaySchema>({
    defaultValues: {
      bankAccount: {
        paymentOptions: !hasBankAccount ? paymentOptions : null,
      },
      wageTaxOptions,
      awnOptions,
      childrenOptions,
    },
    resolver: zodResolver(passingAwaySchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "children",
  });

  const [watchedChildrenOptions, watchedPaymentOptions, watchedAwnOptions] = watch([
    "childrenOptions",
    "bankAccount.paymentOptions",
    "awnOptions",
  ]);

  const isInsideEU =
    watchedPaymentOptions?.find((item: YesNoRadioOption) => item.checked)?.value ===
    PaymentOptionValue.InsideEU;
  const isPartner = !isChild;
  const isAdultChild = isChild && isAdult;
  const isUnderAgeChild = isChild && !isAdult;
  const isAdultChildWithNewWZP = isAdultChild && isWzp2025Apply;
  const showBankAccountForm = !hasBankAccount && (isPartner || isAdultChildWithNewWZP || isUnderAgeChild);
  const showWageTax = isPartner || isAdultChildWithNewWZP || isUnderAgeChild;
  const showAWN = survivor.checkANW;
  const showChildren = isPartner;
  const showChildrenInputs =
    watchedChildrenOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES;
  const showEligibleMsg = isChild && isEligible;
  const showNotEligiblePage = isAdultChild && !isEligible;

  const {
    mutate,
    isPending,
    error: postError,
  } = useMutation({
    mutationFn: updateSurvivor,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["survivors"],
      });
      queryClient.invalidateQueries({
        queryKey: ["toggles"],
      });
      onSuccess?.();
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutate(data);
          }
        });
      }
      if ((postError.cause as { message: string })?.message === "MESSAGE_INVALID_VALUE_BSN") {
        setBsnError(intlMessage("validation.bsn.invalid"));
      }
    },
  });

  const handleAddChild = () => {
    append({ firstName: "", lastName: "", dob: "" as unknown as Date, bsn: "", gender: genderItems[1] });
  };

  const handleRemoveChild = (index: number) => {
    remove(index);
  };

  const onSubmit = async (values: TPassingAwaySchema) => {
    const submitData: SurvivorPayload = {
      bankAccount: {
        isBankAccountEU: isInsideEU,
        bankAccountNumber: removeSpace(
          values.bankAccount?.bankNumber ?? survivor.bankAccount?.bankAccountNumber
        ),
        bankName: values.bankAccount?.bankName,
        bankBicCode: values.bankAccount?.bankCode,
        bankAddress: values.bankAccount?.bankAddress,
        bankLocation: values.bankAccount?.bankLocation,
        bankCountryName: values.bankAccount?.bankLand?.value,
      },
      isTaxCheck: values.wageTaxOptions.find((option) => option.checked)?.value === YesNoValueEnum.YES,
      isANWCheck: values?.awnOptions?.find((option) => option.checked)?.value === YesNoValueEnum.YES,
      fosterChildren: values?.children
        ? values.children.map((child) => ({
            firstName: child.firstName,
            lastName: child.lastName,
            bsn: child.bsn,
            gender: child.gender.value,
            birthdate: formatDate(child.dob),
          }))
        : undefined,
      dateOfDeathParticipant: survivor.dateOfDeathParticipant,
      deceasedId: survivor.deceasedId,
      caseId: survivor.caseId,
      pensionAddress: survivor.pensionAddress,
      pensionEmail: survivor.pensionEmail,
      deceasedName: survivor.deceasedName,
      isAdult: survivor.isAdult,
      survivorId: survivor.survivorId,
      gender: survivor.gender,
      isOrphan: survivor.isOrphan,
      isSicknessBenefit: survivor.isSicknessBenefit,
      brpHouseNumber: survivor.brpHouseNumber,
      brpStreetName: survivor.brpStreetName,
      brpHouseNumberSuffix: survivor.brpHouseNumberSuffix,
      brpPostalCode: survivor.brpPostalCode,
      brpCityName: survivor.brpCityName,
      // temporary field to ignore back end check
      isStudent: false,
    };

    mutate(submitData);
  };

  const errorMessage = bsnError ?? postError?.message;

  const getTitleMessageId = () => {
    if (isChild) return "passing-away.title.orphan";
    if (toggles?.isExPartner) return "passing-away.title.ex-partner";
    return "passing-away.title.partner";
  };

  if (showNotEligiblePage) {
    return (
      <div className="container py-20 grid grid-cols-12">
        <div className="col-span-12 md:col-span-7 md:col-start-4">
          <FormTextBlock heading={<FormattedMessage id="passing-away.title.orphan" />} />
          <Text className="mb-4">
            <FormattedMessage id="passing-away.eligibility.message-no" />
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className="container py-20 grid grid-cols-12">
      <div className="col-span-12 md:col-span-7 md:col-start-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTextBlock heading={<FormattedMessage id={getTitleMessageId()} />} />
          {isSicknessBenefit && (
            <Text className="col-span-12 mb-10">
              <FormattedMessage id="passing-away.sickness-benefit" />
            </Text>
          )}
          {showEligibleMsg && (
            <Text className="mb-4">
              <FormattedMessage id={`passing-away.eligibility.message-${isEligible ? "yes" : "no"}`} />
            </Text>
          )}
          {showBankAccountForm && (
            <div className="col-span-10">
              <BankAccountForm
                name="bankAccount"
                control={control as unknown as Control<FieldValues>}
                isInsideEU={isInsideEU}
              />
            </div>
          )}
          {showWageTax && (
            <FormField
              id="wage-tax"
              labelHeader={<FormattedMessage id="passing-away.wage-tax-title" />}
              className="col-span-12">
              <Text className="mb-2 mt-4">
                <FormattedMessage id="passing-away.wage-tax-text" />
              </Text>
              <Text className="mb-2 mt-4">
                <FormattedMessage id="passing-away.wage-tax-question" />
              </Text>
              <YesNoRadio name="wageTaxOptions" control={control} />
            </FormField>
          )}
          {showAWN && (
            <>
              <FormField
                id="awn"
                labelHeader={<FormattedMessage id="passing-away.awn-header" />}
                className="col-span-12">
                <Text className="mb-2 mt-4">
                  <FormattedMessage id="passing-away.awn-text" />
                </Text>
                <YesNoRadio name="awnOptions" control={control} />
              </FormField>
              {watchedAwnOptions?.find((option) => option.checked)?.value === YesNoValueEnum.NO && (
                <div style={{ marginTop: "-32px", marginBottom: "64px" }}>
                  <Text>
                    <FormattedMessage id="passing-away.awn-no" />
                  </Text>
                </div>
              )}
            </>
          )}
          {showChildren && (
            <FormField
              id="children"
              labelHeader={<FormattedMessage id="passing-away.children" />}
              className="col-span-12">
              {registeredChildren && registeredChildren.length > 0 && (
                <Fragment>
                  <Text className="w-full mb-2 mt-4">
                    <FormattedMessage
                      id="passing-away.have-written"
                      values={{ name: survivor.deceasedName }}
                    />
                  </Text>
                  <table className="w-full pb-4">
                    <tr className="text-left">
                      <th className="p-2">
                        <FormattedMessage id="utils.firstName" />
                      </th>
                      <th className="p-2">
                        <FormattedMessage id="utils.lastName" />
                      </th>
                      <th className="p-2">
                        <FormattedMessage id="passing-away.birthdate" />
                      </th>
                    </tr>
                    {registeredChildren.map((item) => {
                      return (
                        <tr key={item.bsn}>
                          <td className="p-2">{item.firstName}</td>
                          <td className="p-2">{item.lastName}</td>
                          <td className="p-2">{reverseDate(item.birthdate)}</td>
                        </tr>
                      );
                    })}
                  </table>
                </Fragment>
              )}
              {(!registeredChildren || registeredChildren.length === 0) && (
                <Text>
                  <FormattedMessage id="passing-away.children-no" values={{ name: survivor.deceasedName }} />
                </Text>
              )}
              <Text className="mb-2 mt-4">
                <FormattedMessage
                  id="passing-away.foster-stepchildren"
                  values={{
                    name: survivor.deceasedName,
                    WZPValue: survivor.isWzp2025Apply ? WZPValue.after2025 : WZPValue.before2025,
                  }}
                />
              </Text>
              <YesNoRadio className="col-span-12" name="childrenOptions" control={control} />
              {showChildrenInputs && (
                <>
                  <Text className="mb-2">
                    <FormattedMessage id="passing-away.provide-children" />
                  </Text>
                  {fields.map((field, index) => (
                    <div key={field.id} className="mb-10">
                      <FormField
                        id={`children.${index}.firstName`}
                        className="col-span-4"
                        labelHeader={<FormattedMessage id="utils.firstName" />}>
                        <Input
                          className="w-8"
                          id={`children.${index}.firstName`}
                          name={`children.${index}.firstName`}
                          control={control}
                        />
                      </FormField>
                      <FormField
                        id={`children.${index}.lastName`}
                        className="col-span-4"
                        labelHeader={<FormattedMessage id="utils.lastName" />}>
                        <Input
                          id={`children.${index}.lastName`}
                          name={`children.${index}.lastName`}
                          control={control}
                        />
                      </FormField>
                      <FormField
                        id={`children.${index}.dob`}
                        className="col-span-4"
                        labelHeader={<FormattedMessage id="utils.birthdate" />}>
                        <Controller
                          control={control}
                          name={`children.${index}.dob`}
                          render={({ field: { value, onChange } }) => (
                            <DatePicker
                              ariaLabelIconButton="Icon date picker"
                              value={value as unknown as string}
                              onChange={(value) => {
                                onChange(value);
                              }}
                              name={`children.${index}.dob`}
                              feedbackMessage={errors?.children?.[index]?.dob?.message}
                              error={!!errors?.children?.[index]?.dob}
                              yearRange={{ from: 1900, to: new Date().getFullYear() }}
                            />
                          )}
                        />
                      </FormField>
                      <FormField
                        id={`children.${index}.bsn`}
                        className="col-span-4"
                        labelHeader={<FormattedMessage id="utils.bsn" />}>
                        <Input
                          id={`children.${index}.bsn`}
                          name={`children.${index}.bsn`}
                          control={control}
                        />
                      </FormField>
                      <FormField
                        id={`children.${index}.gender`}
                        className="col-span-4"
                        labelHeader={<FormattedMessage id="utils.gender" />}>
                        <Dropdown control={control} name={`children.${index}.gender`} items={genderItems} />
                      </FormField>
                      <IconButton variant="secondary" icon="minus" onClick={() => handleRemoveChild(index)} />
                    </div>
                  ))}
                  <IconButton variant="secondary" icon="plus" onClick={handleAddChild} className="mt-4" />
                </>
              )}
            </FormField>
          )}
          <div className="mt-4">
            <FormSubmit
              buttons={[
                {
                  size: "md",
                  ariaLabel: "button",
                  text: <FormattedMessage id="utils.save" />,
                  type: "submit",
                  disabled: isPending,
                },
              ]}
              errorMessage={errorMessage}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PassingAwayForm;
