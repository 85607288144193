export const validateIBAN = (iban: string) => {
  if (typeof iban !== "string") return false;
  //Step #1 : rearranging the IBAN string by moving the first four digits to the end
  iban = rearrange(iban);
  //Step #2 : converting the letters to their corresponding numbers
  iban = convertToInteger(iban);
  //Step #3 : Performs modulo by doing a sequence of modulo 97 on the formatted IBAN string
  const result = performModulo(iban);
  //If the result is 1, then the IBAN is valid
  return result === 1;
};

const rearrange = (iban: string) => {
  iban = iban.trim().toUpperCase();
  iban = iban.replace(/\s/g, "");
  const start = iban.substring(0, 4);
  const ending = iban.substring(4);
  let rearranged;
  const countryCode = iban.substring(0, 2);
  //ibanCountries - List of Country codes which follows IBAN standards
  const ibanCountries =
    "AD,AE,AL,AT,AZ,BA,BE,BG,BH,BR,BY,CH,CR,CY,CZ,DE,DK,DO,EE,ES,FI,FO,FR,GB,GE,GI,GL,GR,GT,HR,HU,IE,IL,IQ,IS,IT,JO,KW,KZ,LB,LC,LI,LT,LU,LV,MC,MD,ME,MK,MR,MT,MU,NL,NO,PK,PL,PS,PT,QA,RO,RS,SA,SC,SE,SI,SK,SM,ST,SV,TL,TN,TR,UA,VA,VG,XK";
  const isValidCountry = ibanCountries.includes(countryCode);
  if (isValidCountry) {
    rearranged = ending + start;
  } else {
    rearranged = "1234"; //to return some invalid string incase the countrycode is not available in the above 'ibanCountries' variable
  }
  return rearranged;
};

const convertToInteger = (iban: string) => {
  iban = iban.replace(/A/g, "10");
  iban = iban.replace(/B/g, "11");
  iban = iban.replace(/C/g, "12");
  iban = iban.replace(/D/g, "13");
  iban = iban.replace(/E/g, "14");
  iban = iban.replace(/F/g, "15");
  iban = iban.replace(/G/g, "16");
  iban = iban.replace(/H/g, "17");
  iban = iban.replace(/I/g, "18");
  iban = iban.replace(/J/g, "19");
  iban = iban.replace(/K/g, "20");
  iban = iban.replace(/L/g, "21");
  iban = iban.replace(/M/g, "22");
  iban = iban.replace(/N/g, "23");
  iban = iban.replace(/O/g, "24");
  iban = iban.replace(/P/g, "25");
  iban = iban.replace(/Q/g, "26");
  iban = iban.replace(/R/g, "27");
  iban = iban.replace(/S/g, "28");
  iban = iban.replace(/T/g, "29");
  iban = iban.replace(/U/g, "30");
  iban = iban.replace(/V/g, "31");
  iban = iban.replace(/W/g, "32");
  iban = iban.replace(/X/g, "33");
  iban = iban.replace(/Y/g, "34");
  iban = iban.replace(/Z/g, "35");

  return iban;
};

const performModulo = (iban: string) => {
  const part1 = iban.substring(0, 9);
  let result = +part1 % 97;
  let currentPosition = 9;
  let digitsRemaining = true;
  let partN;

  while (digitsRemaining) {
    if (iban.length < currentPosition + 7) {
      digitsRemaining = false;
    }
    if (digitsRemaining) {
      partN = result.toString() + iban.substring(currentPosition, currentPosition + 7);
      result = +partN % 97;
    } else {
      partN = result.toString() + iban.substring(currentPosition);
      result = +partN % 97;
    }
    // code block to be executed
    currentPosition += 7;
  }
  return result;
};
