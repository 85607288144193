import { PageLayout, TextHeader } from "@fonk-gitlab/bpfd-portal";
import { useQuery } from "@tanstack/react-query";
import { FormattedMessage } from "react-intl";
import { RetirementChoiceScreen } from "../../lib/enum";
import { RetirementChoice } from "../../lib/types";
import { getRetirementChoice } from "../../react-query/queries";
import RetirementChoiceForm from "../forms/RetirementChoiceForm";
import { useHandleAuthError } from "../organisms/ErrorHandlingHelper";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";

const RetirementChoicePage = () => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["retirementChoice"],
    queryFn: getRetirementChoice,
    select: (data) => data.retirementChoice as RetirementChoice,
  });

  useHandleAuthError(error, refetch);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return <RequestError />;
  }

  const screenNumber = data?.screen;
  const showScreen1And2 =
    screenNumber === RetirementChoiceScreen.REQUEST_RETIREMENT ||
    screenNumber === RetirementChoiceScreen.SURRENDER_CHOICE;
  const showScreen3 = screenNumber === RetirementChoiceScreen.ENTITLEMENT_CHOICE;
  const showScreen4 = screenNumber === RetirementChoiceScreen.CURRENT_SCENARIO;
  return (
    <PageLayout>
      {showScreen1And2 && (
        <TextHeader
          text={<FormattedMessage id="retirement-choice.header.screen-1-and-2" />}
          subText={<FormattedMessage id="retirement-choice.subText" />}
        />
      )}
      {showScreen3 && (
        <TextHeader
          text={<FormattedMessage id="retirement-choice.header.screen-3-and-4" />}
          subText={<FormattedMessage id="retirement-choice.subText" />}
        />
      )}
      {showScreen4 && (
        <TextHeader
          text={<FormattedMessage id="retirement-choice.header.screen-3-and-4" />}
          subText={<FormattedMessage id="retirement-choice.subText.screen-4" />}
        />
      )}
      <RetirementChoiceForm retirementChoice={data as RetirementChoice} />
    </PageLayout>
  );
};

export default RetirementChoicePage;
