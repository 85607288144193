import { TValueTransferSchema } from "../hooks/schemas/generalSchema";
import {
  EditBankAccountPayload,
  EditTaxExceptionPayload,
  EndCohabitationRequest,
  EndCohabitationResponse,
  FileUploadPayload,
  PlannerBridgingPayload,
  PlannerCalculatePayload,
  PlannerUpdatePayload,
  Preferences,
  RetirementChoices,
  RetirementChoiceScreenPayload,
  SubmitCohabitationRequest,
  SubmitCohabitationResponse,
  SurvivorPayload,
  ValueTransferApprovalCaseType,
} from "../lib/types";
import { getAuthToken, isTokenExpired, refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../utils/token";
import { getUuid } from "../utils/uuid";

export interface DocumentInfo {
  fileName: string;
  container: string;
  storageAccount: string;
}

const baseUrl = window.env.VITE_API_BASE_URL;
const baseUrlDownloadFile = window.env.VITE_API_DOWNLOAD_FILE_BASE_URL;

const getHeaders = () => ({
  Authorization: "Bearer " + getAuthToken() || "",
  "Content-Type": "application/json; Charset=UTF-8",
});

interface ApiRequestOptions {
  method: string;
  url: string;
  headers?: HeadersInit;
  body?: object;
}

const request = async ({ method, url, headers, body }: ApiRequestOptions) => {
  const response = await fetch(url, {
    method,
    headers: headers,
    body: body && JSON.stringify(body),
  });

  if (response.status === parseInt(UNAUTHORIZED_ERROR_CODE)) {
    throw new Error(UNAUTHORIZED_ERROR_CODE);
  }

  if (!response.ok) {
    const error = await response.json();
    throw new Error("An error has occurred while processing the request.", { cause: error });
  }

  return await response.json();
};

const requestWithRefresh = async ({ method, url, headers, body }: ApiRequestOptions) => {
  if (isTokenExpired()) {
    await refreshAuthToken().then((response) => {
      if (response?.accessToken) {
        return request({ method, url, headers, body });
      }
    });
  }
  return request({ method, url, headers, body });
};

export const updateUserCorrespondenceSettings = (newPreference: Preferences) => {
  return requestWithRefresh({
    method: "PATCH",
    url: baseUrl + `persons/${getUuid()}/correspondenceSettings`,
    headers: getHeaders(),
    body: { correspondenceSettings: newPreference },
  });
};

export const updateUserContactDetails = (newPreference: Preferences) =>
  requestWithRefresh({
    method: "PATCH",
    url: baseUrl + `persons/${getUuid()}/preferences`,
    headers: getHeaders(),
    body: { preference: newPreference },
  });

function addOauthToUrl(url: string): string {
  const parts = url.split("/");
  parts.splice(6, 0, "oauth");
  return parts.join("/");
}

export const downloadDocument = (documentInfo: DocumentInfo) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrlDownloadFile,
    body: documentInfo,
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.success || !response.data.csvDirectDownloadUrl) {
        throw new Error("Failed to fetch the file information");
      }

      return fetch(addOauthToUrl(response.data.csvDirectDownloadUrl), {
        method: "GET",
        headers: getHeaders(),
      });
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to download the PDF");
      }

      return response.blob();
    })
    .then((blob: Blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = documentInfo.fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      throw error;
    });

export const savePlanner = (newPlanner: PlannerUpdatePayload) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/planner`,
    headers: getHeaders(),
    body: { planner: newPlanner },
  });

export const calculatePlanner = (newPlanner: PlannerCalculatePayload) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/planner/calculate`,
    headers: getHeaders(),
    body: { planner: newPlanner },
  });

export const updatePartnerAcceptance = () =>
  requestWithRefresh({
    method: "POST",
    url: window.env.VITE_AUTH_BASE_URL + "approval",
    headers: getHeaders(),
    body: {},
  });

export const calculateMaximumAow = (updatedPlanner: PlannerBridgingPayload) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/planner/bridging`,
    headers: getHeaders(),
    body: { planner: updatedPlanner },
  });

export const updateSurvivor = (survivor: SurvivorPayload) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `survivors/${getUuid()}`,
    headers: getHeaders(),
    body: { survivor },
  });

export const uploadFile = (payload: FileUploadPayload) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/documents`,
    headers: getHeaders(),
    body: { documentRequest: payload },
  });

export const deleteScenario = () =>
  requestWithRefresh({
    method: "DELETE",
    url: baseUrl + `persons/${getUuid()}/planner/scenarios`,
    headers: getHeaders(),
    body: {},
  });

export const processAcceptedChoices = (choicesToBeUpdated: RetirementChoices) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/retirementChoice`,
    headers: getHeaders(),
    body: { retirementChoice: choicesToBeUpdated },
  });

export const processAcceptedChoicesPartner = (choicesToBeUpdated: RetirementChoices) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${choicesToBeUpdated?.partnerId}/retirementChoice`,
    headers: getHeaders(),
    body: { retirementChoice: choicesToBeUpdated },
  });

export const processRetirementChoices = (choicesToBeProcessed: RetirementChoiceScreenPayload) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/retirement/choices`,
    headers: getHeaders(),
    body: { retirementChoice: choicesToBeProcessed },
  });

export const processValueTransfer = (valueTransfer: TValueTransferSchema) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/valuetransfer`,
    headers: getHeaders(),
    body: { valueTransfer },
  });

export const updateValueTransferApproval = (valueTransfer: ValueTransferApprovalCaseType) =>
  requestWithRefresh({
    method: "PATCH",
    url: baseUrl + `persons/${getUuid()}/valuetransfer`,
    headers: getHeaders(),
    body: { valueTransfer },
  });

export const updateCohabitation = (
  cohabitation: SubmitCohabitationRequest
): Promise<SubmitCohabitationResponse> =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/cohabitation`,
    headers: getHeaders(),
    body: { cohabitation },
  });

export const updateEndCohabitation = (
  cohabitation: EndCohabitationRequest
): Promise<EndCohabitationResponse> =>
  requestWithRefresh({
    method: "PATCH",
    url: baseUrl + `persons/${getUuid()}/cohabitation`,
    headers: getHeaders(),
    body: { cohabitation },
  });

export const editBankAccount = (payload: EditBankAccountPayload) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/bankAccounts`,
    headers: getHeaders(),
    body: { personalBankAccount: payload },
  });

export const editTaxException = (payload: EditTaxExceptionPayload) =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/taxExceptions`,
    headers: getHeaders(),
    body: { taxException: { isTaxException: payload.isTaxException } },
  });

export const processDivorceCase = () =>
  requestWithRefresh({
    method: "POST",
    url: baseUrl + `persons/${getUuid()}/divorce`,
    headers: getHeaders(),
    body: {},
  });
