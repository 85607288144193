import { Dropdown, FormField, Input, RadioButtons } from "@fonk-gitlab/bpfd-portal";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Fragment } from "react/jsx-runtime";
import { PaymentOptionValue } from "../../constants/passingAway";
import useCountries from "../../hooks/useCountries";
import { useIntlMessage } from "../../hooks/useIntlMessage";
import { formatStringWithSpaces, toAlphanumeric } from "../../utils/string";

interface Props {
  name: string;
  control?: Control<FieldValues>;
  isInsideEU: boolean;
}

const BankAccountForm = ({ name, control, isInsideEU = true }: Props) => {
  const intlMessage = useIntlMessage();
  const { countriesByUserLng } = useCountries();

  const countriesOptions = countriesByUserLng.map((item) => ({
    value: item.countryCode,
    id: item.countryCode,
    label: item.countryName,
  }));

  const paymentOptions = [
    {
      label: intlMessage("passing-away.within-eu"),
      checked: isInsideEU,
      value: PaymentOptionValue.InsideEU,
      id: "1",
    },
    {
      label: intlMessage("passing-away.outside-eu"),
      checked: !isInsideEU,
      value: PaymentOptionValue.OutsideEU,
      id: "2",
    },
  ];

  return (
    <Fragment>
      <RadioButtons
        className="col-span-12"
        name={`${name}.paymentOptions`}
        options={paymentOptions}
        control={control}
      />
      <Controller
        name={`${name}.bankNumber`}
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <FormField id="bankNumber" labelText={<FormattedMessage id="bankAccount.bankNumber" />}>
            <Input
              id="bankNumber"
              name={`${name}.bankNumber`}
              value={value}
              onChange={(e) => {
                const value = e.target.value;
                const bankAccount = formatStringWithSpaces(toAlphanumeric(value), 4) ?? "";
                onChange(bankAccount.toLocaleUpperCase());
              }}
              placeholder={intlMessage("bankAccount.bankNumber-placeholder")}
              error={!!(errors?.bankAccount as FieldErrors<FieldValues>)?.bankNumber}
              feedbackMessage={
                (errors?.bankAccount as FieldErrors<FieldValues>)?.bankNumber?.message as string
              }
            />
          </FormField>
        )}
      />
      {!isInsideEU && (
        <Fragment>
          <FormField id="bankName" labelText={<FormattedMessage id="bankAccount.bankName" />}>
            <Input
              id="bankName"
              name={`${name}.bankName`}
              control={control}
              placeholder={intlMessage("bankAccount.bankNumber-placeholder")}
            />
          </FormField>
          <FormField id="bankCode" labelText={<FormattedMessage id="bankAccount.bankSwiftCode" />}>
            <Controller
              name={`${name}.bankCode`}
              control={control}
              render={({ field: { value, onChange }, formState: { errors } }) => (
                <Input
                  id="bankCode"
                  name={`${name}.bankCode`}
                  placeholder={intlMessage("bankAccount.bankSwiftCode-placeholder")}
                  value={value}
                  onChange={(e) => {
                    const value = e.target.value;
                    onChange(value.toLocaleUpperCase());
                  }}
                  error={!!(errors?.bankAccount as FieldErrors<FieldValues>)?.bankCode}
                  feedbackMessage={
                    (errors?.bankAccount as FieldErrors<FieldValues>)?.bankCode?.message as string
                  }
                />
              )}
            />
          </FormField>
          <FormField id="bankAddress" labelText={<FormattedMessage id="bankAccount.bankAddress" />}>
            <Input
              id="bankAddress"
              name={`${name}.bankAddress`}
              control={control}
              placeholder={intlMessage("bankAccount.bankAddress-placeholder")}
            />
          </FormField>
          <FormField id="bankLocation" labelText={<FormattedMessage id="bankAccount.bankLocation" />}>
            <Input
              id="bankLocation"
              name={`${name}.bankLocation`}
              control={control}
              placeholder={intlMessage("bankAccount.bankLocation-placeholder")}
            />
          </FormField>
          <FormField id="bankAddress" labelText={<FormattedMessage id="bankAccount.bankLand" />}>
            <Dropdown
              items={countriesOptions}
              control={control}
              name={`${name}.bankLand`}
              placeholder={intlMessage("bankAccount.bankLand-placeholder")}
            />
          </FormField>
        </Fragment>
      )}
    </Fragment>
  );
};

export default BankAccountForm;
