import { Button } from "@fonk-gitlab/bpfd-portal";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        key="return"
        size="lg"
        onClick={() => {
          navigate(-1);
        }}
        text={<FormattedMessage id="utils.return" />}
      />
    </div>
  );
};

export default BackButton;
