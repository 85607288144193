import { PensionPlannerCategory, SitName, SituationCategory } from "../lib/enum";
import { PensionPlanner, Situation } from "../lib/types";

export const hasSurrender = (situations: Situation[] | undefined) => {
  if (!situations) return false;

  return !!situations
    ?.find((item) => item.category === SituationCategory.DEFAULT)
    ?.amounts?.find((amount) => amount.name === SitName.SURRENDER);
};

export const hasSurrenderAmount = (pensionPlanners: PensionPlanner[]) => {
  if (pensionPlanners.length <= 0) return false;
  return !!pensionPlanners?.find(
    (item) => item.surrenderAmount > 0 && item.category === PensionPlannerCategory.CHOICE
  );
};
