import { Button, InformationPanel } from "@fonk-gitlab/bpfd-portal";
import { FormattedMessage } from "react-intl";
import useCountries from "../../../hooks/useCountries";
import { Person } from "../../../lib/types";
import { HREF } from "../../../routes/routes";

interface Props {
  user: Person;
}

const MyDataThirdTab: React.FC<Props> = ({ user }) => {
  const tailwindClasses = "lg:col-span-6 lg:col-start-4 col-span-12";
  const bankAccount = user?.personalBankAccount;

  const { countriesByUserLng } = useCountries();

  const country = countriesByUserLng.find((item) => item.countryCode === bankAccount?.countryCode);

  const bankAccountType = bankAccount?.isBankAccountEU ? "within" : "outside";

  const details = [
    {
      label: <FormattedMessage id="my-data.bank-account" />,
      input: bankAccount !== null ? <FormattedMessage id={`passing-away.${bankAccountType}-eu`} /> : "",
    },
    {
      label: <FormattedMessage id="my-data.bank-number" />,
      input: bankAccount?.bankAccount ?? "",
    },
    {
      label: <FormattedMessage id="bankAccount.bankName" />,
      input: bankAccount?.bankName ?? "",
    },
    {
      label: <FormattedMessage id="bankAccount.bankSwiftCode" />,
      input: bankAccount?.bankBicCode ?? "",
    },
    {
      label: <FormattedMessage id="bankAccount.bankAddress" />,
      input: bankAccount?.bankAddress ?? "",
    },
    {
      label: <FormattedMessage id="my-data.location-bank" />,
      input: bankAccount?.locationBank ?? "",
    },
    {
      label: <FormattedMessage id="bankAccount.bankLand" />,
      input: country?.countryName ?? "",
    },
  ];

  return (
    <div className={tailwindClasses}>
      <InformationPanel
        buttons={[
          <Button
            key="edit"
            size="md"
            text={<FormattedMessage id="utils.edit" />}
            href={HREF.BANK_ACCOUNT}
          />,
        ]}
        className="flex-1 h-full"
        header={<FormattedMessage id="my-data.my-data-tab" />}
        informationDetails={details}
      />
    </div>
  );
};

export default MyDataThirdTab;
