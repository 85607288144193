import { useIntl } from "react-intl";
import { z } from "zod";
import { useIntlMessage } from "../useIntlMessage";
import { Provider } from "../../components/pages/actions/ValueTransferPage";
import { BLOCK_EMOJI, BLOCK_HTML, BLOCK_SPECIAL_CHARACTERS, BLOCK_SQL } from "../../lib/constants";

export const useGivenNameSchema = () => {
  const intlMessage = useIntlMessage();

  const displayNameRegex = /^.+$/;

  return z.object({
    givenName: z
      .string()
      .min(1, intlMessage("validation.given-name.min"))
      .max(30, intlMessage("validation.given-name.max"))
      .refine((value: string) => !BLOCK_SQL.test(value), intlMessage("validation.special-characters"))
      .refine((value: string) => !BLOCK_HTML.test(value), intlMessage("validation.special-characters"))
      .refine(
        (value: string) => !BLOCK_SPECIAL_CHARACTERS.test(value),
        intlMessage("validation.special-characters")
      )
      .refine((value: string) => !BLOCK_EMOJI.test(value), intlMessage("validation.special-characters"))
      .refine((value: string) => displayNameRegex.test(value), intlMessage("validation.special-characters")),
  });
};

export type TGivenNameSchema = z.infer<ReturnType<typeof useGivenNameSchema>>;

export const useCorrespondenceDetailsSchema = () => {
  const intlMessage = useIntlMessage();

  return z
    .object({
      language: z.object({
        id: z.string(),
        label: z.string(),
        value: z.string(),
      }),
      wayOfCommunication: z.object({
        id: z.string(),
        label: z.string(),
        value: z.string(),
      }),
      email: z.string().optional(),
      isReceiveNews: z.boolean(),
    })
    .superRefine(({ wayOfCommunication, email }, ctx) => {
      if (wayOfCommunication?.value !== "D") {
        return;
      }

      if (!email) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intlMessage("validation.required"),
          path: ["email"],
        });
      }
    });
};

export type TCorrespondenceDetailsSchema = z.infer<ReturnType<typeof useCorrespondenceDetailsSchema>>;

export const useCorrespondenceAddressDetailsSchema = () => {
  const intlMessage = useIntlMessage();

  return z
    .object({
      isSameAddress: z.boolean(),
      country: z
        .object({
          id: z.string(),
          label: z.string(),
          value: z.string(),
        })
        .nullable()
        .optional(),
      addressLine1: z.string().min(2, intlMessage("validation.address.range")).optional().or(z.literal("")),
      addressLine2: z.string().min(2, intlMessage("validation.address.range")).optional().or(z.literal("")),
      addressLine3: z.string().min(2, intlMessage("validation.address.range")).optional().or(z.literal("")),
    })
    .superRefine(({ isSameAddress, country, addressLine1 }, ctx) => {
      if (isSameAddress === true) {
        return;
      }

      if (!country) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intlMessage("validation.required"),
          path: ["country"],
        });
      }

      if (!addressLine1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: intlMessage("validation.required"),
          path: ["addressLine1"],
        });
      }
    });
};

export type TCorrespondenceAddressDetailsSchema = z.infer<
  ReturnType<typeof useCorrespondenceAddressDetailsSchema>
>;

export const usePhoneSchema = () => {
  const intl = useIntl();
  const intlMessage = (id: string): string => intl.formatMessage({ id: id });

  const phoneRegex = /^[+]?[0-9- ]*$/;
  return z.object({
    phoneNumber: z
      .string()
      .regex(phoneRegex, intlMessage("validation.phone-number.format"))
      .refine((value) => value.replace(/\D/g, "").length >= 8, intlMessage("validation.phone-number.length"))
      .refine(
        (value) => value.replace(/\D/g, "").length <= 15,
        intlMessage("validation.phone-number.length")
      ),
  });
};

export type TPhoneSchema = z.infer<ReturnType<typeof usePhoneSchema>>;

export const useSalarySchema = () => {
  const intl = useIntl();
  const intlMessage = (id: string): string => intl.formatMessage({ id: id });
  const intlTypeError = (id: string): { invalid_type_error: string } => ({
    invalid_type_error: intlMessage(id),
  });

  return z.object({
    salary: z.coerce
      .number(intlTypeError("validation.salary.format"))
      .positive(intlMessage("validation.salary.positive"))
      .max(999999.99, intlMessage("validation.salary.max-header")),
    hours: z.coerce
      .number(intlTypeError("validation.hours.format"))
      .positive(intlMessage("validation.hours.positive"))
      .max(99.99, intlMessage("validation.hours.max-header")),
  });
};

export type TSalarySchema = z.infer<ReturnType<typeof useSalarySchema>>;

export const useValueTransferSchema = (providers: Provider[]) => {
  const intl = useIntl();
  const intlMessage = (id: string): string => intl.formatMessage({ id: id });
  const contractRegex = /^[^"\\'<>%[\]{}]*$/;

  return z.object({
    contractNumber: z
      .string()
      .min(1, intlMessage("value-transfer.required-fields"))
      .regex(contractRegex, intlMessage("value-transfer.invalid-character")),
    providerId: z
      .object({ value: z.string(), label: z.string() })
      .nullable()
      .refine((provider) => provider !== null, { message: intlMessage("value-transfer.provider-not-found") })
      .transform((provider) => provider.value)
      .refine(
        (id) => {
          return providers.map((provider) => provider.providerId).includes(id);
        },
        { message: intlMessage("value-transfer.provider-not-found") }
      ),
  });
};

export type TValueTransferSchema = z.infer<ReturnType<typeof useValueTransferSchema>>;
