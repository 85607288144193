import { ActionPanel, Button, PageLayout } from "@fonk-gitlab/bpfd-portal";
import { Fragment } from "react/jsx-runtime";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { actionImageLink } from "../../utils/pensionLinks";
import PensionOverview from "../organisms/pensionOverview/PensionOverview";
import IntroPanelHome from "../organisms/IntroPanelHome";
import Loading from "../organisms/Loading";
import RequestError from "../organisms/RequestError";
import { useNavigate } from "react-router-dom";
import { HREF } from "../../routes/routes";
import { FormattedMessage } from "react-intl";

const Homepage = () => {
  const navigate = useNavigate();
  const { user, isLoading, toggles } = useAuthentication() ?? {};

  if (isLoading) {
    return <Loading />;
  }

  if (!user || !toggles) {
    return <RequestError />;
  }

  return (
    <PageLayout>
      <Fragment key=".0">
        <div className="container flex-grow py-20">
          {user && (
            <div className="grid grid-cols-12 gap-x-1 md:gap-x-2">
              <div className="col-span-12 col-start-1 lg:col-start-2 lg:col-span-6 ">
                <div className="mb-2">
                  <IntroPanelHome user={user} toggles={toggles} />
                </div>
                <div>
                  <ActionPanel
                    className="mb-2"
                    header={""}
                    image={{
                      alt: "",
                      height: 200,
                      src: actionImageLink,
                      width: 200,
                    }}
                    buttons={[
                      <Button
                        key="toMyActions"
                        text={<FormattedMessage id="home.submit-change" />}
                        size="sm"
                        ariaLabel={<FormattedMessage id="home.submit-change" />}
                        onClick={() => {
                          navigate(HREF.ACTIONS);
                        }}
                      />,
                    ]}
                  />
                </div>
              </div>
              <div className="col-start-1 col-span-12  w-full md:col-span-12 lg:col-span-4">
                <PensionOverview />
              </div>
            </div>
          )}
        </div>
      </Fragment>
    </PageLayout>
  );
};

export default Homepage;
