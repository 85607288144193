import { FormattedMessage } from "react-intl";
import { usePlanner } from "../../../context/PlannerContext";
import { camelToKebabCase } from "../../../utils/camelToKebabCase";

import "./AcceptanceUserFinancialInfo.css";
import { BankAccount, Planner } from "../../../lib/types";

interface Props {
  bankAccount?: BankAccount;
  taxExemption?: boolean;
  disabled?: boolean;
}

const AcceptanceUserFinancialInfo: React.FC<Props> = ({ bankAccount, taxExemption, disabled }) => {
  const person = usePlanner()?.planner?.person;

  // Filter the person object to remove dateOfBirth
  const { dateOfBirth: _dateOfBirth, personalBankAccount, ...rest } = person || ({} as Planner["person"]);
  const bankAccountNumber = bankAccount?.bankAccountNumber ?? personalBankAccount?.bankAccount;
  const filteredPerson = {
    ...rest,
    ...(bankAccountNumber && { bankAccountNumber }),
    disabled: disabled ?? rest?.disabled,
    hasTaxException: taxExemption ?? rest.hasTaxException,
  };
  const keys = Object.keys(filteredPerson);
  const values = Object.values(filteredPerson);

  const formattedKey = (key: string) => {
    if (key === "disabled") return "work-incapacity";
    return camelToKebabCase(key);
  };

  const formattedValue = (value: string | boolean | null | undefined) => {
    return typeof value === "boolean" ? <FormattedMessage id={`utils.${value ? "yes" : "no"}`} /> : value;
  };

  return (
    <div className="row">
      <div className="column">
        {keys.map((key) => (
          <div key={key}>{<FormattedMessage id={`utils.${formattedKey(key)}`} />}</div>
        ))}
      </div>
      <div className="column">
        {values.map((value, i) => (
          <div key={keys[i]}>
            <strong>{formattedValue(value)}</strong>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcceptanceUserFinancialInfo;
