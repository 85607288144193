import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import {
  ButtonAction,
  ButtonType,
  ExchangeType,
  HLLHType,
  PensionPlannerCategory,
  RetirementChoiceScreen,
} from "../../lib/enum";
import { ButtonConfig, EntitlementChoice, PensionPlanner, RetirementChoice } from "../../lib/types";
import { hasSurrenderAmount } from "../../utils/hasSurrender";
import { isEmptyObject } from "../../utils/objectUtils";
import { getCalendarByAge, getTotalMonths } from "../../utils/plannerCalendar";
import { isEmptyString } from "../../utils/string";

interface DateValues {
  yearsInScenario: number;
  monthsInScenario: number;
  dateOfBirth: Date;
  pensionRetirementDate: Date;
  defaultRetirementDate: Date;
  aowDate: Date;
}

const REQUEST_RETIREMENT = RetirementChoiceScreen.REQUEST_RETIREMENT;
const SURRENDER_CHOICE = RetirementChoiceScreen.SURRENDER_CHOICE;
const ENTITLEMENT_CHOICE = RetirementChoiceScreen.ENTITLEMENT_CHOICE;
const CURRENT_SCENARIO = RetirementChoiceScreen.CURRENT_SCENARIO;
const BUTTON_CONFIG = {
  [REQUEST_RETIREMENT]: [
    {
      label: "retirement-choice.buttonFive",
      type: ButtonType.SUBMIT,
      action: ButtonAction.UPDATE_CHOICE,
    },
    {
      label: "retirement-choice.buttonSix",
      type: ButtonType.SUBMIT,
      action: ButtonAction.UPSERT_CHOICE_PENSION,
    },
    {
      label: "retirement-choice.buttonSeven",
      type: ButtonType.SUBMIT,
      action: ButtonAction.UPSERT_CHOICE_AOW,
    },
    {
      label: "retirement-choice.buttonEight",
      type: ButtonType.SUBMIT,
      action: ButtonAction.UPSERT_CHOICE_DEFAULT,
    },
    {
      label: "retirement-choice.buttonNine",
      type: ButtonType.SUBMIT,
      action: ButtonAction.DELETE_CHOICE,
    },
  ],
  [SURRENDER_CHOICE]: [
    {
      label: "retirement-choice.buttonTen",
      type: ButtonType.SUBMIT,
      action: ButtonAction.SURRENDER_CHOICE_PENSION,
    },
    {
      label: "retirement-choice.buttonEleven",
      type: ButtonType.SUBMIT,
      action: ButtonAction.SURRENDER_CHOICE_AOW,
    },
    {
      label: "retirement-choice.buttonTwelve",
      type: ButtonType.SUBMIT,
      action: ButtonAction.SURRENDER_CHOICE_DEFAULT,
    },
    {
      label: "retirement-choice.buttonThirteen",
      type: ButtonType.SUBMIT,
      action: ButtonAction.DELETE_CHOICE_SURRENDER,
    },
  ],
  [ENTITLEMENT_CHOICE]: [
    {
      label: "retirement-choice.buttonFourteen",
      type: ButtonType.BUTTON,
      action: ButtonAction.TEXT_SCENARIO,
    },
    {
      label: "retirement-choice.buttonFifteen",
      type: ButtonType.BUTTON,
      action: ButtonAction.TEXT_SURRENDER,
    },
    {
      label: "retirement-choice.buttonSixteen",
      type: ButtonType.SUBMIT,
      action: ButtonAction.UPSERT_CHOICE,
    },
  ],
  [CURRENT_SCENARIO]: [
    {
      label: "retirement-choice.buttonSeventeen",
      type: ButtonType.SUBMIT,
      action: ButtonAction.GO_TO_PLANNER,
    },
    {
      label: "retirement-choice.buttonEighteen",
      type: ButtonType.SUBMIT,
      action: ButtonAction.DELETE_SCENARIO,
    },
  ],
};

const defineButtonByScreen = (screen: string, category: string) => {
  const isRequestScreen = screen === REQUEST_RETIREMENT;
  if (category === PensionPlannerCategory.PENSION) {
    return isRequestScreen ? ButtonAction.UPSERT_CHOICE_PENSION : ButtonAction.SURRENDER_CHOICE_PENSION;
  }

  if (category === PensionPlannerCategory.AOW) {
    return isRequestScreen ? ButtonAction.UPSERT_CHOICE_AOW : ButtonAction.SURRENDER_CHOICE_AOW;
  }

  if (category === PensionPlannerCategory.DEFAULT) {
    return isRequestScreen ? ButtonAction.UPSERT_CHOICE_DEFAULT : ButtonAction.SURRENDER_CHOICE_DEFAULT;
  }

  return "";
};

const getRetirementDate = (choices: EntitlementChoice) => {
  return choices?.partlyRetirementStartDate ?? choices?.retirementStartDate;
};

const formatDate = (intl: IntlShape, date: Date) => {
  return intl.formatDate(date, {
    year: "numeric",
    month: "long",
  });
};

const getChoiceAmount = (intl: IntlShape, pensionPlanners: PensionPlanner[]) => {
  const pensionPlannerItem = pensionPlanners?.find((item) => item.category === PensionPlannerCategory.CHOICE);
  if (pensionPlannerItem === undefined) return "";

  const hasSurrender = hasSurrenderAmount(pensionPlanners);
  const opPartlyAmount = pensionPlannerItem?.partlyAmount;
  const opFullyAmount = pensionPlannerItem?.opAmount;
  const opHighLowAmount = pensionPlannerItem?.opHighLowAmount;
  const aowAmount = pensionPlannerItem?.aowAmount;
  const retirementAmount = opPartlyAmount > 0 ? opPartlyAmount : opFullyAmount;
  const accruedAmount = (retirementAmount + opHighLowAmount + aowAmount) / 12;

  if (hasSurrender) {
    return intl.formatNumber(pensionPlannerItem?.surrenderAmount, { minimumFractionDigits: 2 });
  }

  return intl.formatNumber(accruedAmount, { minimumFractionDigits: 2 });
};

const getPensionAmount = (intl: IntlShape, category: string, pensionPlanners: PensionPlanner[]) => {
  const pensionItem = pensionPlanners?.find((item) => item.category === category);
  const hasSurrender = hasSurrenderAmount(pensionPlanners);
  if (!pensionItem) return intl.formatNumber(0, { minimumFractionDigits: 2 });

  if (hasSurrender) {
    return intl.formatNumber(pensionItem?.surrenderAmount, { minimumFractionDigits: 2 });
  }

  return intl.formatNumber(pensionItem?.opAmount / 12, { minimumFractionDigits: 2 });
};

const generateScenarioText = (
  intl: IntlShape,
  label: string,
  choices: EntitlementChoice,
  pensionPlanners: PensionPlanner[]
) => {
  const hasHighLowOption =
    !isEmptyString(choices?.differentHeightsOverTime) && !isEmptyString(choices?.differentHeightsEndDate);
  const hasExchangeOPPPOption = !isEmptyString(choices?.exchangeOptions) && choices?.exchangeAmount !== null;
  const hasLumpSum = choices?.lumpSumAmount !== null && choices?.lumpSumAmount > 0;
  const retirementDate = getRetirementDate(choices);
  let includedText = "";
  let highLowText = "";
  let opppText = "";
  let lumpsumText = "";

  if (hasHighLowOption || hasExchangeOPPPOption || hasLumpSum) {
    includedText = intl.formatMessage({ id: "retirement-choice.buttonFourteen.included-text" });
  }

  if (hasHighLowOption) {
    const highLowEndDate = new Date(choices.differentHeightsEndDate ?? "");
    const totalMonths = getTotalMonths(
      new Date(retirementDate),
      new Date(highLowEndDate.getFullYear(), highLowEndDate.getMonth(), highLowEndDate.getDate() + 1)
    );
    highLowText = intl.formatMessage(
      { id: "retirement-choice.buttonFourteen.highlow" },
      {
        highlowOption:
          choices?.differentHeightsOverTime === HLLHType.HL
            ? intl.formatMessage({ id: "scenario.hllh-HL" })
            : intl.formatMessage({ id: "scenario.hllh-LH" }),
        totalMonths: totalMonths.toString(),
      }
    );
  }

  if (hasExchangeOPPPOption) {
    opppText = intl.formatMessage(
      { id: "retirement-choice.buttonFourteen.oppp" },
      {
        exchangeAmount: intl.formatNumber(choices?.exchangeAmount ?? 0, { minimumFractionDigits: 2 }),
        highOrLow:
          choices?.exchangeOptions === ExchangeType.OPPP
            ? intl.formatMessage({ id: "scenario.hllh-LH" })
            : intl.formatMessage({ id: "scenario.hllh-HL" }),
      }
    );
  }

  if (hasLumpSum) {
    lumpsumText = intl.formatMessage(
      { id: "retirement-choice.buttonFourteen.lumpsum" },
      {
        lumpsumAmount: intl.formatNumber(choices?.lumpSumAmount ?? 0, { minimumFractionDigits: 2 }),
        lumpsumDate: formatDate(intl, new Date(retirementDate)),
      }
    );
  }

  return (
    <FormattedMessage
      id={label}
      values={{
        retirementDate: formatDate(intl, new Date(retirementDate)),
        accruedAmount: getChoiceAmount(intl, pensionPlanners),
        includedText: !isEmptyString(includedText) ? includedText : null,
        highlow: !isEmptyString(highLowText) ? (
          <ul style={{ listStyleType: "disc", paddingLeft: "3rem" }}>
            <li>{highLowText}</li>
          </ul>
        ) : null,
        oppp: !isEmptyString(opppText) ? (
          <ul style={{ listStyleType: "disc", paddingLeft: "3rem" }}>
            <li>{opppText}</li>
          </ul>
        ) : null,
        lumpsum: !isEmptyString(lumpsumText) ? (
          <ul style={{ listStyleType: "disc", paddingLeft: "3rem" }}>
            <li>{lumpsumText}</li>
          </ul>
        ) : null,
      }}
    />
  );
};

const createButtonText = (
  intl: IntlShape,
  config: ButtonConfig,
  dateValues: DateValues,
  pensionPlanners: PensionPlanner[],
  choices: EntitlementChoice
) => {
  if (config?.action === ButtonAction.UPDATE_CHOICE) {
    const retirementAge = getCalendarByAge(
      dateValues?.dateOfBirth,
      dateValues?.yearsInScenario,
      dateValues?.monthsInScenario
    );
    return (
      <FormattedMessage
        id={config.label}
        values={{
          date: formatDate(intl, new Date(retirementAge?.yearNumber, retirementAge?.monthNumber)),
        }}
      />
    );
  }

  if ([ButtonAction.UPSERT_CHOICE_PENSION, ButtonAction.SURRENDER_CHOICE_PENSION].includes(config?.action)) {
    return (
      <FormattedMessage
        id={config?.label}
        values={{
          date: formatDate(intl, dateValues?.pensionRetirementDate),
          amount: getPensionAmount(intl, PensionPlannerCategory.PENSION, pensionPlanners),
        }}
      />
    );
  }

  if (config?.action === ButtonAction.UPSERT_CHOICE_AOW) {
    return (
      <FormattedMessage
        id={config?.label}
        values={{
          date: formatDate(intl, dateValues?.aowDate),
          amount: getPensionAmount(intl, PensionPlannerCategory.AOW, pensionPlanners),
        }}
      />
    );
  }

  if (config?.action === ButtonAction.SURRENDER_CHOICE_AOW) {
    return (
      <FormattedMessage
        id={config?.label}
        values={{
          date: formatDate(
            intl,
            new Date(dateValues?.aowDate.getFullYear(), dateValues?.aowDate.getMonth() + 1)
          ),
          amount: getPensionAmount(intl, PensionPlannerCategory.AOW, pensionPlanners),
        }}
      />
    );
  }

  if ([ButtonAction.UPSERT_CHOICE_DEFAULT, ButtonAction.SURRENDER_CHOICE_DEFAULT].includes(config?.action)) {
    return (
      <FormattedMessage
        id={config?.label}
        values={{
          date: formatDate(intl, new Date(dateValues?.defaultRetirementDate)),
          amount: getPensionAmount(intl, PensionPlannerCategory.DEFAULT, pensionPlanners),
        }}
      />
    );
  }

  if (config?.action === ButtonAction.TEXT_SURRENDER) {
    return (
      <FormattedMessage
        id={config?.label}
        values={{
          date: formatDate(intl, new Date(getRetirementDate(choices))),
          amount: getChoiceAmount(intl, pensionPlanners),
        }}
      />
    );
  }

  if (config?.action === ButtonAction.TEXT_SCENARIO) {
    return generateScenarioText(intl, config?.label, choices, pensionPlanners);
  }

  if (
    [
      ButtonAction.DELETE_CHOICE,
      ButtonAction.DELETE_CHOICE_SURRENDER,
      ButtonAction.GO_TO_PLANNER,
      ButtonAction.DELETE_SCENARIO,
      ButtonAction.UPSERT_CHOICE,
    ].includes(config?.action)
  ) {
    return <FormattedMessage id={config?.label} />;
  }

  return "";
};

const retirementButtonNumberVisible = (screen: string, dateValues: DateValues, hasScenario: boolean) => {
  const retirementButtonNumbers: (ButtonAction | "")[] = [];
  const yearsInScenario = dateValues?.yearsInScenario;
  const monthsInScenario = dateValues?.monthsInScenario;
  const pensionRetirementDate = dateValues?.pensionRetirementDate;
  const defaultRetirementDate = dateValues?.defaultRetirementDate;
  const aowDate = dateValues?.aowDate;
  const today = new Date();

  if (hasScenario && (yearsInScenario !== 0 || (yearsInScenario === 0 && monthsInScenario !== 0))) {
    retirementButtonNumbers.push(ButtonAction.UPDATE_CHOICE);
  }

  if (!!pensionRetirementDate && pensionRetirementDate < defaultRetirementDate) {
    retirementButtonNumbers.push(defineButtonByScreen(screen, PensionPlannerCategory.PENSION));
  }

  if (!!aowDate && aowDate >= today) {
    retirementButtonNumbers.push(defineButtonByScreen(screen, PensionPlannerCategory.AOW));
  }

  if (defaultRetirementDate > today && defaultRetirementDate.getTime() !== aowDate.getTime()) {
    retirementButtonNumbers.push(defineButtonByScreen(screen, PensionPlannerCategory.DEFAULT));
  }

  if (screen === REQUEST_RETIREMENT) {
    retirementButtonNumbers.push(ButtonAction.DELETE_CHOICE);
  }

  if (screen === SURRENDER_CHOICE) {
    retirementButtonNumbers.push(ButtonAction.DELETE_CHOICE_SURRENDER);
  }

  return retirementButtonNumbers;
};

const entitlementButtonNumberVisible = (choices: EntitlementChoice) => {
  const entitlementButtonNumbers: (ButtonAction | "")[] = [];

  if (choices?.indicationSurrender) {
    entitlementButtonNumbers.push(ButtonAction.TEXT_SURRENDER);
  }
  if (!choices?.indicationSurrender) {
    entitlementButtonNumbers.push(ButtonAction.TEXT_SCENARIO);
  }
  entitlementButtonNumbers.push(ButtonAction.UPSERT_CHOICE);

  return entitlementButtonNumbers;
};

const createButtons = (
  intl: IntlShape,
  buttonInfo: ButtonConfig[],
  dateValues: DateValues,
  pensionPlanners: PensionPlanner[],
  choices: EntitlementChoice
) => {
  buttonInfo?.forEach((item) => {
    const buttonText = createButtonText(intl, item, dateValues, pensionPlanners, choices);
    item.text = buttonText;
  });

  return buttonInfo;
};

const useCreateChoicesText = (retirementChoice: RetirementChoice) => {
  const { scenario, person, participation, pensionPlanners, entitlementChoice, screen } =
    retirementChoice ?? {};
  const dateValues = {
    yearsInScenario: scenario?.partlyRetirementStartYears ?? scenario?.retirementStartYears ?? 0,
    monthsInScenario: scenario?.partlyRetirementStartMonths ?? scenario?.retirementStartMonths ?? 0,
    dateOfBirth: new Date(person?.dateOfBirth),
    pensionRetirementDate: new Date(participation?.pensionRetirementDate),
    defaultRetirementDate: new Date(participation?.defaultRetirementDate),
    aowDate: new Date(participation?.aowRetirementDate),
  };
  const intl = useIntl();
  let buttonNumbers: (ButtonAction | "")[] = [];

  if (screen === REQUEST_RETIREMENT) {
    buttonNumbers = retirementButtonNumberVisible(REQUEST_RETIREMENT, dateValues, !isEmptyObject(scenario));
  }

  if (screen === SURRENDER_CHOICE) {
    buttonNumbers = retirementButtonNumberVisible(SURRENDER_CHOICE, dateValues, !isEmptyObject(scenario));
  }

  if (screen === ENTITLEMENT_CHOICE) {
    buttonNumbers = entitlementButtonNumberVisible(entitlementChoice);
  }

  if (screen === CURRENT_SCENARIO) {
    buttonNumbers = [ButtonAction.GO_TO_PLANNER, ButtonAction.DELETE_SCENARIO];
  }

  const buttons: ButtonConfig[] = BUTTON_CONFIG[screen].filter((item) => buttonNumbers.includes(item.action));

  return createButtons(intl, buttons, dateValues, pensionPlanners, entitlementChoice);
};

export default useCreateChoicesText;
