import {
  DataList,
  FormField,
  FormImportantMessage,
  FormTextBlock,
  Input,
  Popup,
  RadioButtonProps,
  RadioButtons,
} from "@fonk-gitlab/bpfd-portal";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { usePlanner } from "../../../context/PlannerContext";
import useFormatNumber from "../../../hooks/useFormatNumber";
import { useIntlMessage } from "../../../hooks/useIntlMessage";
import { ExchangeType, UseMaximumExchange } from "../../../lib/enum";
import { retiringSoonResourceLink } from "../../../utils/pensionLinks";

const ExchangeOPPPOption = () => {
  const { planner, exchangeAfterCalculate, isCalculateSuccess } = usePlanner() || {};
  const format = useFormatNumber();
  const { setValue, watch, control, getValues } = useFormContext();
  const intlMessage = useIntlMessage();

  const exchangeTypeKey = "retirementOption.exchangeOption.exchangeType";
  const isMaxExchangeKey = "retirementOption.exchangeOption.isMaxExchange";
  const exchangeAmountKey = "retirementOption.exchangeOption.exchangeAmount";

  const exchange = planner?.retirement?.exchange;
  const isSurrenderAtRetirement = exchange?.isSurrenderAtRetirement;
  const isWarningOPPP = exchange?.isWarningOPPP;
  const exchangeType = exchange?.typeOfExchange;
  const currentOP = exchange?.currentOP ?? 0;
  const currentPP = exchange?.currentPP ?? 0;
  const afterExchangeOP = exchange?.afterExchangeOP ?? 0;
  const afterExchangePP = exchange?.afterExchangePP ?? 0;
  const defaultMaxExchangeOP = exchange?.maxExchangeOP ?? 0;
  const defaultMaxExchangePP = exchange?.maxExchangePP ?? 0;

  const [watchedExchangeType, watchedIsMaxExchange] = watch([exchangeTypeKey, isMaxExchangeKey]);

  const exchangeTypeOptions: RadioButtonProps[] = useMemo(() => {
    const showBothOptions = defaultMaxExchangePP > 0 && defaultMaxExchangeOP > 0;
    return [ExchangeType.OPPP, ExchangeType.PPOP]
      .map((type) => ({
        checked:
          (type === ExchangeType.OPPP && defaultMaxExchangePP === 0) ||
          (type === ExchangeType.PPOP && defaultMaxExchangeOP === 0) ||
          type === exchangeType,
        id: type,
        label: intlMessage(`planner.exchange.${type}`),
        value: type,
      }))
      .filter(
        (option) =>
          showBothOptions ||
          (option.id === ExchangeType.OPPP && defaultMaxExchangePP === 0) ||
          (option.id === ExchangeType.PPOP && defaultMaxExchangeOP === 0)
      );
  }, [exchangeType, defaultMaxExchangeOP, defaultMaxExchangePP, intlMessage]);

  const exchangeAmountOptions: RadioButtonProps[] = useMemo(() => {
    const hasExchangeTypeChecked = !!exchangeTypeOptions.find((item) => item.checked);
    return [UseMaximumExchange.YES, UseMaximumExchange.NO].map((option) => ({
      checked: option === UseMaximumExchange.YES && hasExchangeTypeChecked,
      id: option,
      label: intlMessage(`utils.${option}`),
      value: option,
    }));
  }, [exchangeTypeOptions, intlMessage]);

  const [isDisabledAmountInput, setIsDisabledAmountInput] = useState(true);
  const [info, setInfo] = useState(false);
  const [afterAmounts, setAfterAmounts] = useState({ calcOppp: afterExchangePP, calcPpop: afterExchangeOP });
  const [firstTimeShowExchangeAmount, setFirstTimeShowExchangeAmount] = useState<boolean>(
    Boolean(planner?.scenario?.isMaxExchangeOppp === false && planner?.scenario?.exchangeEntitlementsAmount)
  );
  const isDisplayWarning = !watchedExchangeType || !watchedIsMaxExchange;

  const onClickHandler = () => {
    setInfo((prevState) => !prevState);
  };

  useEffect(() => {
    if (watchedExchangeType || watchedIsMaxExchange) return;

    const shouldValidate = exchangeTypeOptions?.some((item) => item.checked);

    exchangeTypeOptions && setValue(exchangeTypeKey, exchangeTypeOptions, { shouldValidate });
  }, [setValue, exchangeTypeOptions, watchedExchangeType, watchedIsMaxExchange]);

  useEffect(() => {
    const handleExchangeTypeChange = () => {
      const scenarioExchangeAmountOption = getValues(isMaxExchangeKey)?.find(
        (item: RadioButtonProps) => item.checked
      );
      const isExchangeTypeSelected =
        watchedExchangeType?.some((option: RadioButtonProps) => option.checked) ?? false;
      if (!watchedExchangeType || !isExchangeTypeSelected) return;
      const newAmountOptions = exchangeAmountOptions.map((option: RadioButtonProps) => ({
        ...option,
        checked:
          scenarioExchangeAmountOption === undefined
            ? option.value === UseMaximumExchange.YES
            : scenarioExchangeAmountOption?.value === option.value,
      }));
      setValue(isMaxExchangeKey, newAmountOptions);
    };

    handleExchangeTypeChange();
  }, [watchedExchangeType, setValue, exchangeAmountOptions, getValues]);

  useEffect(() => {
    const handleExchangeAmountOptionChange = () => {
      if (!watchedIsMaxExchange || !watchedExchangeType) return;

      const exchangeEntitlementsAmount = planner?.scenario?.exchangeEntitlementsAmount;
      const scenarioExchangeAmount = exchangeEntitlementsAmount ? exchangeEntitlementsAmount / 12 : undefined;

      const selectedAmountOption = watchedIsMaxExchange?.find((option: RadioButtonProps) => option.checked);
      const exchangeTypeSelected = watchedExchangeType?.find((option: RadioButtonProps) => option.checked);
      const maxExchangeAmount =
        exchangeTypeSelected?.id === ExchangeType.OPPP ? defaultMaxExchangeOP : defaultMaxExchangePP;

      if (selectedAmountOption?.value === UseMaximumExchange.YES) {
        setIsDisabledAmountInput(true);
        setValue(exchangeAmountKey, format(maxExchangeAmount, { minimumFractionDigits: 2 }), {
          shouldValidate: true,
        });
        if (firstTimeShowExchangeAmount) {
          setFirstTimeShowExchangeAmount(false);
        }
      }

      if (selectedAmountOption?.value === UseMaximumExchange.NO) {
        setIsDisabledAmountInput(false);
        setValue(
          exchangeAmountKey,
          scenarioExchangeAmount && firstTimeShowExchangeAmount
            ? format(scenarioExchangeAmount, { maximumFractionDigits: 2 })
            : ""
        );
      }
    };

    handleExchangeAmountOptionChange();
  }, [
    watchedIsMaxExchange,
    watchedExchangeType,
    setValue,
    getValues,
    defaultMaxExchangeOP,
    defaultMaxExchangePP,
    isDisabledAmountInput,
    format,
    firstTimeShowExchangeAmount,
    // this var is causing unexpected calling Effect, comment it until find out another solution
    // planner?.scenario?.exchangeEntitlementsAmount,
  ]);

  useEffect(() => {
    const updateAfterAmounts = () => {
      if (exchangeAfterCalculate) {
        setAfterAmounts({
          calcOppp: exchangeAfterCalculate.afterExchangePp,
          calcPpop: exchangeAfterCalculate.afterExchangeOp,
        });
      }
    };

    updateAfterAmounts();
  }, [exchangeAfterCalculate, setAfterAmounts]);

  useEffect(() => {
    const resetFirstTimeShowExchangeAmount = () => {
      if (isCalculateSuccess) {
        setFirstTimeShowExchangeAmount(true);
      }
    };

    resetFirstTimeShowExchangeAmount();
  }, [isCalculateSuccess]);

  return (
    <>
      <FormTextBlock
        body={<FormattedMessage id="planner.exchange.exchange-pp-text" />}
        className="col-span-12"
        heading={<FormattedMessage id="planner.exchange.exchange-pp-title" />}
      />
      <FormTextBlock
        body={<FormattedMessage id="planner.exchange.exchange-op-text" />}
        className="col-span-12"
        heading={<FormattedMessage id="planner.exchange.exchange-op-title" />}
        showIcon
        onIconClick={onClickHandler}
      />
      <Popup
        open={info}
        body={
          <FormattedMessage
            id="planner.exchange.for-more-info-icon"
            values={{
              link: (
                <Link to={retiringSoonResourceLink} target="_blank">
                  <FormattedMessage id="planner.exchange.altText-link" />
                </Link>
              ),
            }}
          />
        }
        onClose={onClickHandler}
        popupVariant="information"
      />
      {isSurrenderAtRetirement && (
        <FormImportantMessage
          heading={<FormattedMessage id="home.intro-panel-warning" />}
          body={<FormattedMessage id="planner.exchange.surrender-at-retirement" />}
          className="col-span-12 mb-2"
        />
      )}
      {isWarningOPPP && (
        <FormImportantMessage
          heading={<FormattedMessage id="home.intro-panel-warning" />}
          body={<FormattedMessage id="planner.exchange.amount-override" />}
          className="col-span-12 mb-2"
        />
      )}
      {isDisplayWarning && (
        <FormImportantMessage
          heading={<FormattedMessage id="home.intro-panel-warning" />}
          body={<FormattedMessage id="planner.warning.required-fields" />}
          className="col-span-12 mb-15"
        />
      )}
      <FormField
        className="col-span-12"
        id={exchangeTypeKey}
        labelText={<FormattedMessage id="planner.exchange.exchange-type-title" />}>
        <RadioButtons name={exchangeTypeKey} options={exchangeTypeOptions} control={control} />
      </FormField>
      <DataList
        className="col-span-12"
        columnVisibility={[true, true]}
        dataRows={[
          {
            header: "",
            index: "0",
            inputData: [
              {
                index: "1",
                label: intlMessage("planner.exchange.op"),
                object1: { strikethrough: false, value: format(currentOP, { minimumFractionDigits: 2 }) },
                object2: {
                  strikethrough: false,
                  value: format(afterAmounts.calcPpop, { minimumFractionDigits: 2 }),
                },
              },
              {
                index: "2",
                label: intlMessage("planner.exchange.pp"),
                object1: { strikethrough: false, value: format(currentPP, { minimumFractionDigits: 2 }) },
                object2: {
                  strikethrough: false,
                  value: format(afterAmounts.calcOppp, { minimumFractionDigits: 2 }),
                },
              },
            ],
          },
        ]}
        itemHeaders={{
          header1: "",
          header2: <FormattedMessage id="planner.exchange.current" />,
          header3: <FormattedMessage id="planner.exchange.after-exchange" />,
        }}
        totalVisibleColumns={3}
      />
      <FormField
        className="col-span-12"
        id={isMaxExchangeKey}
        labelText={<FormattedMessage id="planner.exchange.maximum-exchange" />}>
        <RadioButtons name={isMaxExchangeKey} options={exchangeAmountOptions} control={control} />
      </FormField>
      <FormField
        className="col-span-12"
        id={exchangeAmountKey}
        labelText={<FormattedMessage id="planner.exchange.amount" />}>
        <Input
          id={exchangeAmountKey}
          name={exchangeAmountKey}
          placeholder="€ 0,00"
          control={control}
          disabled={isDisabledAmountInput}
        />
      </FormField>
    </>
  );
};

export default ExchangeOPPPOption;
