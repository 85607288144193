import { FormField, FormSubmit, Icon, PageLayout, Popup, Text, TextHeader } from "@fonk-gitlab/bpfd-portal";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Control, FieldValues, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { PaymentOptionValue } from "../../../constants/passingAway";
import { TMyDataBankAccount, useMyDataBankAccount } from "../../../hooks/schemas/useMyDataBankAccount";
import { useIntlMessage } from "../../../hooks/useIntlMessage";
import { YesNoValueEnum } from "../../../lib/enum";
import { editBankAccount, editTaxException } from "../../../react-query/mutations";
import { HREF } from "../../../routes/routes";
import { refreshAuthToken, UNAUTHORIZED_ERROR_CODE } from "../../../utils/token";
import yesNoOptions from "../../../utils/YesNoOptions";
import YesNoRadio from "../../atoms/YesNoRadio";
import BankAccountForm from "../../forms/BankAccountForm";

const MyDataBankAccount = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const intlMessage = useIntlMessage();
  const intl = useIntl();

  const [info, setInfo] = useState<boolean>(false);

  const paymentOptions = [
    {
      label: intlMessage("passing-away.within-eu"),
      checked: true,
      value: PaymentOptionValue.InsideEU,
      id: "1",
    },
    {
      label: intlMessage("passing-away.outside-eu"),
      checked: false,
      value: PaymentOptionValue.OutsideEU,
      id: "2",
    },
  ];

  const wageTaxOptions = yesNoOptions(intl, "tax", { yes: false, no: true });

  const defaultValues = {
    bankAccount: {
      paymentOptions,
      bankNumber: "",
    },
    wageTaxOptions,
  };
  const { handleSubmit, control, watch } = useForm<TMyDataBankAccount>({
    mode: "onChange",
    resolver: zodResolver(useMyDataBankAccount()),
    defaultValues,
  });

  const {
    mutateAsync: mutateBankAccount,
    isPending: isPendingEditBankAccount,
    error: bankAccountError,
  } = useMutation({
    mutationFn: editBankAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutateBankAccount(data);
          }
        });
      }
    },
  });

  const {
    mutateAsync: mutateTaxException,
    isPending: isPendingEditTaxException,
    error: taxError,
  } = useMutation({
    mutationFn: editTaxException,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
    onError: async (postError, data): Promise<void> => {
      if (postError?.message === UNAUTHORIZED_ERROR_CODE) {
        return await refreshAuthToken().then((response) => {
          if (response?.accessToken) {
            return mutateTaxException(data);
          }
        });
      }
    },
  });

  const [watchedPaymentOptions] = watch(["bankAccount.paymentOptions"]);

  const onSubmit = async (values: TMyDataBankAccount) => {
    const bankAccountPayload = {
      locationBank: values.bankAccount.bankLocation,
      isBankAccountEU:
        values.bankAccount?.paymentOptions?.find((item) => item.checked)?.value ===
        PaymentOptionValue.InsideEU,
      countryName: values.bankAccount.bankLand?.label,
      countryCode: values.bankAccount.bankLand?.value,
      bankName: values?.bankAccount?.bankName,
      bankBicCode: values.bankAccount?.bankCode,
      bankAddress: values.bankAccount?.bankAddress,
      bankAccount: values.bankAccount.bankNumber,
    };

    const taxPayload = {
      isTaxException: values.wageTaxOptions?.find((item) => item.checked)?.value === YesNoValueEnum.YES,
    };

    await Promise.all([mutateTaxException(taxPayload), mutateBankAccount(bankAccountPayload)]);

    navigate(HREF.MY_DATA_TAB_3);
  };

  const isInsideEU =
    watchedPaymentOptions?.find((item) => item.checked)?.value === PaymentOptionValue.InsideEU;

  return (
    <PageLayout>
      <TextHeader text={<FormattedMessage id="my-data.edit-payment-information" />} subText="" />
      <div className="grid grid-cols-12 container py-20 flex-grow">
        <div className="col-span-12 md:col-span-7 md:col-start-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <BankAccountForm
              control={control as unknown as Control<FieldValues>}
              name="bankAccount"
              isInsideEU={isInsideEU}
            />
            <FormField id="wage-tax" className="col-span-12">
              <Text className="mb-2 mt-4 flex justify-between items-center mb-5">
                <FormattedMessage id="passing-away.wage-tax-question" />
                <div onClick={() => setInfo(true)} role="button">
                  <Icon
                    color="fill-black"
                    hoverColor="hover:fill-black group-hover:fill-black"
                    icon="information"
                    size="sm"
                  />
                </div>
              </Text>
              <YesNoRadio name="wageTaxOptions" control={control} />
            </FormField>

            <div className="mt-4">
              <FormSubmit
                buttons={[
                  {
                    size: "md",
                    ariaLabel: "button",
                    text: <FormattedMessage id="utils.save" />,
                    type: "submit",
                    disabled: isPendingEditBankAccount || isPendingEditTaxException,
                  },
                  {
                    size: "md",
                    ariaLabel: "button",
                    text: <FormattedMessage id="utils.cancel" />,
                    type: "button",
                    onClick: () => {
                      navigate(HREF.MY_DATA_TAB_3);
                    },
                  },
                ]}
                errorMessage={bankAccountError?.message ?? taxError?.message}
              />
            </div>
          </form>
        </div>
      </div>

      {info && (
        <Popup
          open={info}
          body={<FormattedMessage id="my-data.edit-payment.tax-information" />}
          onClose={() => setInfo(false)}
          popupVariant="information"
        />
      )}
    </PageLayout>
  );
};

export default MyDataBankAccount;
