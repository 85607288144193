import React from "react";
import { FormattedMessage } from "react-intl";
import { CardHeader, PageLayout, TextHeader } from "@fonk-gitlab/bpfd-portal";
import { Item } from "@fonk-gitlab/bpfd-portal/dist/components/organisms/dataTableOverview/DataTableOverview.types";
import MyActionDataTable from "../organisms/dataTable/MyActionDataTable";
import { useIntlMessage } from "../../hooks/useIntlMessage";
import { useQuery } from "@tanstack/react-query";
import { getOutstandingRequests } from "../../react-query/queries";
import { OutstandingAction, OutstandingRequest } from "../../lib/types";
import Loading from "../organisms/Loading";
import { useHandleAuthError } from "../organisms/ErrorHandlingHelper";
import { CardContent } from "@fonk-gitlab/bpfd-portal/dist/components/atoms/card/Card.types";
import { useAuthentication } from "../../context/AuthenticatedContext";
import { EMyActionOption, OpenActions } from "../../lib/enum";
import { useNavigate } from "react-router-dom";
import {
  EMPTY_STRING,
  MYACTION_OPEN_ACTIONS,
  MYACTION_OPEN_ACTIONS_LABEL,
  MYACTION_OPTION_LINK,
} from "../../lib/constants";
import { removeLastUnderscoreAndAfter } from "../../utils/removeLastUnderscoreAndAfter";

const Actions = () => {
  const navigate = useNavigate();
  const intlMessage = useIntlMessage();

  const { toggles } = useAuthentication() || {};
  const {
    data: cases,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["case"],
    queryFn: getOutstandingRequests,
  });

  useHandleAuthError(error, refetch);

  const formatActions = React.useMemo<CardContent[]>(
    () => [
      ...(toggles?.isRetireEarlyOrLater
        ? [
            {
              index: EMyActionOption.EARLY_LATE,
              text: <FormattedMessage id="my.action.retire.early.title" />,
            },
          ]
        : []),

      {
        index: EMyActionOption.VALUE_TRANSFER,
        text: <FormattedMessage id="my.action.transfer.value.title" />,
      },

      ...(toggles?.isStartCohabitation
        ? [
            {
              index: EMyActionOption.COHABIT,
              text: <FormattedMessage id="my.action.cohabit.title" />,
            },
          ]
        : []),

      ...(toggles?.isEndCohabitation
        ? [
            {
              index: EMyActionOption.END_COHABIT,
              text: <FormattedMessage id="my.action.end-cohabit.title" />,
            },
          ]
        : []),
    ],
    [toggles?.isRetireEarlyOrLater, toggles?.isStartCohabitation, toggles?.isEndCohabitation]
  );
  const formatStatusRequests = React.useMemo<Item[]>(() => {
    const result: OutstandingRequest[] = cases?.statusRequests ?? [];

    return result.map((item) => ({
      id: item.caseId,
      caseNumber: item.caseNumber,
      request: item.recordType,
      status: item.status,
    }));
  }, [cases?.statusRequests]);

  const formatOpenActions = React.useMemo<CardContent[]>(() => {
    const result: OutstandingAction[] = cases?.openActions ?? [];

    return result.map((item, i) => ({
      index: (item.type + "_" + i) as unknown as number, //Faraday type is only number but a string is needed
      text: <FormattedMessage id={`${MYACTION_OPEN_ACTIONS_LABEL[item.type as OpenActions]}`} />,
      dateText: item.createDateStr,
    }));
  }, [cases?.openActions]);

  const handleNavigateActions = (key: EMyActionOption) => {
    navigate(MYACTION_OPTION_LINK[key]);
  };

  const handleNavigateOpenActions = (index: number) => {
    const cleanIndex = removeLastUnderscoreAndAfter(index.toString());
    navigate(MYACTION_OPEN_ACTIONS[cleanIndex as OpenActions]);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageLayout>
      <div className="bg-gradient-mint-blue-light flex flex-col min-h-screen">
        <TextHeader text={<FormattedMessage id="my.action.page.title" />} subText={EMPTY_STRING} />
        <section className="bg-white">
          <div className="container py-20 flex-grow">
            <CardHeader
              text={<FormattedMessage id="my.action.outstanding.action.title" />}
              withDate
              contents={formatOpenActions}
              onClick={handleNavigateOpenActions}
            />
            <CardHeader
              key="actions"
              className="pt-15"
              contents={formatActions}
              onClick={handleNavigateActions}
              text={<FormattedMessage id="my.action.request.title" />}
            />
          </div>
        </section>

        <MyActionDataTable
          title={intlMessage("my.action.outstanding.request.title")}
          data={formatStatusRequests}
        />
      </div>
    </PageLayout>
  );
};

export default Actions;
