import { useCallback } from "react";
import { FormatNumberOptions, useIntl } from "react-intl";

const useFormatNumber = () => {
  const intl = useIntl();
  const format = useCallback(
    (value: Parameters<Intl.NumberFormat["format"]>[0], options?: FormatNumberOptions) =>
      intl.formatNumber(value, options),
    [intl]
  );
  return format;
};

export default useFormatNumber;
