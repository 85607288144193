import { FormattedMessage, IntlShape } from "react-intl";
import { EARLIER_LATER_FULLY } from "../lib/constants";
import { ExchangeType, UseMaximumExchange, UseMaximumExchangeAow } from "../lib/enum";
import { Employment, GenericRadioItem, Retirement, Scenario } from "../lib/types";
import { isNumber } from "./number";

export interface FormScenario {
  employmentOptions: {
    earningSalaryOption: {
      isSelected?: boolean;
      ageYear?: number;
      ageMonth?: number;
      salary?: number;
    };
    quittingWorkOption: {
      isSelected?: boolean;
      ageYear?: number;
      ageMonth?: number;
    };
    workingTimeOption: {
      isSelected?: boolean;
      ageYear?: number;
      ageMonth?: number;
      hours?: number;
    };
  };
  retirementOption: {
    earlierOrLaterRetirementOptions: {
      fullyOrPartial: {
        id: string;
        label: string;
        value: number;
      };
      isEarlierOrLaterRetirementSelected?: boolean;
      fullAgeYear?: number;
      fullAgeMonth?: number;
      partialAgeYear?: number;
      partialAgeMonth?: number;
      percentage?: number;
    };
    exchangeOption: {
      isSelected?: boolean;
      isMaxExchange?: GenericRadioItem[];
      exchangeType?: GenericRadioItem[];
      exchangeAmount?: string;
    };
    bridgingOption: {
      isSelected?: boolean;
      maxExchangeAowAmount?: number;
      exchangeAmount?: string;
      bridgingAmount?: string;
      isMaxExchangeAow?: GenericRadioItem[];
    };
    highLowLowHigh: {
      isSelected?: boolean;
      exchangeOptions?: GenericRadioItem[];
      numberOfMonths?: number;
    };
    surrenderOptions: {
      isSurrenderSelected?: boolean;
      isRefrainFromSurrenderSelected?: boolean;
      ageYear?: number;
      ageMonth?: number;
    };
  };
}

const roundDown = (value: number) => Math.floor(value * 100) / 100;
const roundUp = (value: number) => Math.ceil(value * 100) / 100;

const createEmploymentOptions = (
  scenario: Scenario,
  employment: Employment
): FormScenario["employmentOptions"] => {
  const {
    salaryStartYear,
    salaryStartMonth,
    salary,
    quitWorkingStartYear,
    quitWorkingStartMonth,
    partTimePercentageStartYear,
    partTimePercentageStartMonth,
    partTimePercentage,
  } = scenario;

  const { hours: hoursEmployment } = employment;
  return {
    workingTimeOption: {
      ...(partTimePercentageStartYear !== null && { ageYear: partTimePercentageStartYear }),
      ...(partTimePercentageStartMonth !== null && { ageMonth: partTimePercentageStartMonth }),
      ...(partTimePercentage !== null &&
        partTimePercentage !== 1 && { hours: roundDown(partTimePercentage * hoursEmployment) }),
    },
    earningSalaryOption: {
      ...(salaryStartYear !== null && { ageYear: salaryStartYear }),
      ...(salaryStartMonth !== null && { ageMonth: salaryStartMonth }),
      ...(salary !== null && { salary: salary }),
    },
    quittingWorkOption: {
      ...(quitWorkingStartYear !== null && { ageYear: quitWorkingStartYear }),
      ...(quitWorkingStartMonth !== null && { ageMonth: quitWorkingStartMonth }),
    },
  };
};

const createExchangeRadioButtons = (value: string, intl: IntlShape): GenericRadioItem[] => {
  const isHL = value === "HL";
  return [
    {
      id: "HL",
      checked: isHL,
      value: "HL",
      label: intl.formatMessage({ id: "planner.exchange-overtime.high-than-low" }),
    },
    {
      id: "LH",
      checked: !isHL,
      value: "LH",
      label: intl.formatMessage({ id: "planner.exchange-overtime.low-than-high" }),
    },
  ];
};

const createExChangeOptions = ({
  exchangeEntitlementsAmount,
  isMaxExchangeOppp,
  exchangeEntitlementsOption,
  retirement,
}: {
  exchangeEntitlementsAmount: number | null;
  isMaxExchangeOppp: boolean;
  exchangeEntitlementsOption: string | null;
  retirement: Retirement;
}) => {
  return {
    ...(exchangeEntitlementsAmount !== null &&
      isMaxExchangeOppp !== null && {
        isMaxExchange: createIsMaxExchange(isMaxExchangeOppp),
      }),
    ...(exchangeEntitlementsOption !== null && {
      exchangeType: createExchangeType({ exchangeEntitlementsOption, retirement }),
    }),
    ...(exchangeEntitlementsAmount !== null && {
      exchangeAmount: roundUp(exchangeEntitlementsAmount / 12).toString(),
    }),
  };
};

const createExchangeType = ({
  exchangeEntitlementsOption,
  retirement,
}: {
  exchangeEntitlementsOption: string | null;
  retirement: Retirement;
}): GenericRadioItem[] => {
  const defaultMaxExchangeOP = retirement?.exchange?.maxExchangeOP ?? 0;
  const defaultMaxExchangePP = retirement?.exchange?.maxExchangePP ?? 0;
  const showBothOptions = defaultMaxExchangePP > 0 && defaultMaxExchangeOP > 0;
  return [ExchangeType.OPPP, ExchangeType.PPOP]
    .map((type) => ({
      checked: exchangeEntitlementsOption === type,
      id: type,
      label: <FormattedMessage id={`planner.exchange.${type}`} />,
      value: type,
    }))
    .filter(
      (option) =>
        showBothOptions ||
        (option.id === ExchangeType.OPPP && defaultMaxExchangePP === 0) ||
        (option.id === ExchangeType.PPOP && defaultMaxExchangeOP === 0)
    );
};

const createIsMaxExchange = (isMaxExchangeOPPP: boolean): GenericRadioItem[] => {
  return [
    {
      id: UseMaximumExchange.NO,
      checked: isMaxExchangeOPPP === false,
      value: UseMaximumExchange.NO,
      label: <FormattedMessage id={`utils.no`} />,
    },
    {
      id: UseMaximumExchange.YES,
      checked: isMaxExchangeOPPP === true,
      value: UseMaximumExchange.YES,
      label: <FormattedMessage id={`utils.yes`} />,
    },
  ];
};

const createIsMaxExchangeAow = (isMaxExchangeOPPP: boolean): GenericRadioItem[] => {
  return [
    {
      id: UseMaximumExchangeAow.YES,
      checked: isMaxExchangeOPPP === true,
      value: UseMaximumExchangeAow.YES,
      label: <FormattedMessage id={`utils.yes`} />,
    },
    {
      id: UseMaximumExchangeAow.NO,
      checked: isMaxExchangeOPPP === false,
      value: UseMaximumExchangeAow.NO,
      label: <FormattedMessage id={`utils.no`} />,
    },
  ];
};

const fullyOrPartial = (partlyRetirementPercentage: number | null, intl: IntlShape) => {
  const FULLY = intl.formatMessage({ id: "utils.fully" });
  const percentageLabel = partlyRetirementPercentage !== 100 ? `${partlyRetirementPercentage}%` : FULLY;
  const percentageValue =
    partlyRetirementPercentage !== null ? roundDown(partlyRetirementPercentage) : EARLIER_LATER_FULLY;
  return {
    id: partlyRetirementPercentage?.toString() ?? EARLIER_LATER_FULLY.toString(),
    label: percentageLabel.toString(),
    value: percentageValue,
  };
};

const createRetirementOption = (
  scenario: Scenario,
  intl: IntlShape,
  retirement: Retirement
): FormScenario["retirementOption"] => {
  const {
    retirementStartYear,
    retirementStartMonth,
    partlyRetirementStartYear,
    partlyRetirementStartMonth,
    partlyRetirementPercentage,
    isMaxExchangeOppp,
    isMaxExchangeAow,
    differentHeightsOverTime,
    differentHeightsEndYearInMonth,
    exchangeEntitlementsOption,
    exchangeEntitlementsAmount,
    bridgingAmount,
    convertSurrenderToNormal,
    indicationSurrender,
  } = scenario;

  return {
    earlierOrLaterRetirementOptions: {
      fullyOrPartial: fullyOrPartial(partlyRetirementPercentage, intl),
      ...(retirementStartYear !== null && { fullAgeYear: retirementStartYear }),
      ...(retirementStartMonth !== null && { fullAgeMonth: retirementStartMonth }),
      ...(partlyRetirementStartYear !== null && { partialAgeYear: partlyRetirementStartYear }),
      ...(partlyRetirementStartMonth !== null && { partialAgeMonth: partlyRetirementStartMonth }),
    },
    exchangeOption: createExChangeOptions({
      exchangeEntitlementsAmount,
      isMaxExchangeOppp,
      exchangeEntitlementsOption,
      retirement,
    }),
    bridgingOption: {
      isMaxExchangeAow: createIsMaxExchangeAow(isMaxExchangeAow),
      ...(isMaxExchangeAow
        ? bridgingAmount && { maxExchangeAowAmount: bridgingAmount / 12 }
        : { maxExchangeAowAmount: 0 }),
      ...(bridgingAmount !== null && {
        bridgingAmount: (bridgingAmount / 12).toString(),
      }),
    },
    highLowLowHigh: {
      ...(differentHeightsOverTime !== null && {
        exchangeOptions: createExchangeRadioButtons(differentHeightsOverTime, intl),
      }),
      ...(differentHeightsEndYearInMonth !== null && { numberOfMonths: differentHeightsEndYearInMonth }),
    },
    surrenderOptions: {
      isRefrainFromSurrenderSelected: convertSurrenderToNormal,
      isSurrenderSelected: indicationSurrender,
      ...(retirementStartYear !== null && { ageYear: retirementStartYear }),
      ...(retirementStartMonth !== null && { ageMonth: retirementStartMonth }),
    },
  };
};

type employmentSection =
  | "bridgingOption"
  | "earlierOrLaterRetirementOptions"
  | "exchangeOption"
  | "surrenderOptions"
  | "highLowLowHigh";

// Add isSelected attribute to each sub-object of employmentOptions and retirementOptions (except surrenderOptions)
const addIsSelectedAttribute = (
  options: Record<string, FormScenario["retirementOption"][employmentSection]>
) => {
  for (const key in options) {
    if (Object.prototype.hasOwnProperty.call(options, key)) {
      const subObject = options[key];

      const isEmpty = Object.keys(subObject).length === 0;
      const hasOnlyDefaultProp = (prop: string) => Object.keys(subObject).every((attr) => attr === prop);

      switch (key) {
        case "bridgingOption":
          {
            const bridgingAmount = (subObject as FormScenario["retirementOption"]["bridgingOption"])
              .bridgingAmount;
            const isSelected = !!(bridgingAmount && isNumber(+bridgingAmount));
            options[key] = {
              ...subObject,
              isSelected,
            };
          }
          break;
        case "earlierOrLaterRetirementOptions":
          options[key] = {
            ...subObject,
            isEarlierOrLaterRetirementSelected: !hasOnlyDefaultProp("fullyOrPartial"),
          };
          break;
        case "surrenderOptions":
          options[key] = {
            ...subObject,
          };
          break;
        default:
          options[key] = {
            ...subObject,
            isSelected: !isEmpty,
          };
          break;
      }
    }
  }
};

const formatScenarioToForm = (
  scenario: Scenario,
  employment: Employment,
  intl: IntlShape,
  retirement: Retirement
): FormScenario => {
  const newScenario: FormScenario = {
    employmentOptions: createEmploymentOptions(scenario, employment),
    retirementOption: createRetirementOption(scenario, intl, retirement),
  };

  addIsSelectedAttribute(newScenario.employmentOptions);
  addIsSelectedAttribute(newScenario.retirementOption);

  return newScenario;
};

export default formatScenarioToForm;
